import { reactive, ref } from 'vue'

import { ReactivityUtil } from '@/utils/Reactivity'

const isActive = ref(false)
const isFailed = ref(false)
const options = reactive({})

class Loading {
  start(opts = {}) {
    isActive.value = true
    isFailed.value = false

    if (opts === false) opts = {}

    opts.blocking = opts.blocking ?? true
    ReactivityUtil.reAssign(options, opts)
  }

  end() {
    isActive.value = false
    isFailed.value = false
  }

  failed() {
    isFailed.value = true
    isActive.value = false
  }

  getState() {
    return {
      active: isActive,
      failed: isFailed,
      options: options,
    }
  }
}

export default new Loading()
