import tippy, { followCursor } from 'tippy.js'
import 'tippy.js/dist/tippy.css'

tippy.setDefaultProps({
  arrow: true,
  theme: 'material',
})

/**
 * return an option-object, for use with tippy
 * @param  {DOMNode} el
 * @return {Object}
 */
function setOptions(binding, el) {
  let options = binding.value

  if (typeof options !== 'object') {
    options = { content: options }
  }

  if (el && el.getAttribute('html-tooltip') !== null) {
    options.allowHTML = true
  }

  if (el && el.getAttribute('interactive-tooltip') !== null) {
    options.interactive = true
    // need to append to body, otherwise content clipped in modal
    options.appendTo = document.body
  }

  return options
}

/**
 * return an new/existing tippy-instance
 * @param  {DOMNode} el
 * @return {Object}
 */
function getInstance(el) {
  let instance

  if (el._tippy) {
    instance = el._tippy
  } else {
    instance = tippy(el, { plugins: [followCursor] })
  }

  return instance
}

const createTippyInstance = (el, binding) => {
  const content = binding?.value?.content ?? binding.value ?? false

  // passed false => tear down tooltip
  if (content === false) {
    el?._tippy?.destroy()
    return
  }

  const instance = getInstance(el)
  const options = setOptions(binding, el)
  instance.setProps(options)
}

export default {
  beforeMount: createTippyInstance,
  updated: createTippyInstance,
  unmounted: el => {
    if (el._tippy) el._tippy.destroy()
  },
}
