<template>
  <!-- prettier-ignore -->
  <Teleport
    v-if="$props.modelValue"
    :to="teleportTarget"
  >
    <Transition
      appear
      name="flash"
    >
      <div
        :id="id"
        ref="rootElement"
        class="ovp-modal-container"
        tabIndex="0"
        @keydown.esc="handleEsc"
      >
        <div
          class="overlay"
          tabIndex="-1"
          @click="handleOverlayClick"
        />
        <Transition
          appear
          name="scale"
        >
          <div
            class="dialog"
            :class="dialogClasses"
            :role="role"
          >
            <div
              class="document"
              role="document"
            >
              <button
                v-if="closeOptions.closeIcon"
                class="close-button"
                aria-label="Close Dialog"
                data-a11y-dialog-hide
                type="button"
                @click="close"
              >
                <the-icon
                  name="xmark"
                  :art="browser.isDesktop ? 'light' : 'solid'"
                />
              </button>

              <template v-if="$props.modalTitle">
                <Modal.Header :text="$props.modalTitle" />
              </template>

              <template v-if="$slots.default">
                <Modal.Content>
                  <slot :modal-props="slotProps" />
                </Modal.Content>
              </template>

              <template v-if="$slots.primary || $slots.secondary">
                <Modal.Footer>
                  <template #secondary>
                    <slot name="secondary" />
                  </template>
                  <template #primary>
                    <slot name="primary" />
                  </template>
                </Modal.Footer>
              </template>
            </div>
          </div>
        </Transition>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup>
import { computed, nextTick, onBeforeMount, onBeforeUnmount, ref, watch, watchEffect } from 'vue'

import A11yDialog from 'a11y-dialog'
import { v4 as uuidv4 } from 'uuid'

import events$ from '@/services/Events'
import { logError } from '@/helpers/Logger'

import useApplication from '@/hooks/useApplication'
import useBrowser from '@/hooks/useBrowser'

import * as Modal from '@/components/Modal'

import { EVENT_MODAL } from '@/config/events'

// HOOKS
const { isWww } = useApplication()
const { browser } = useBrowser()

// INIT
/**
 * Represent the different types a dialog can be closed:
 *  * LOCKED:   Dialog has no close option, close option must be defined within parent component
 *  * X_ONLY:   Dialog can be closed via Close Icon or ESC-Key
 *  * OPEN:     Dialog can be closed via Close Icon, ESC-Key and by clicking outside of the dialog
 *  * DEFAULT:  Dialog can be closed via Close Icon, ESC-Key and by clicking outside of the dialog
 *
 * @type {Readonly<{LOCKED: {closeOnMaskClick: boolean, closeOnEsc: boolean, closeIcon: boolean}, X_ONLY: {closeOnMaskClick: boolean, closeOnEsc: boolean, closeIcon: boolean}, DEFAULT: {closeOnMaskClick: boolean, closeOnEsc: boolean, closeIcon: boolean}, OPEN: {closeOnMaskClick: boolean, closeOnEsc: boolean, closeIcon: boolean}}>}
 */
const DialogCloseTypes = Object.freeze({
  LOCKED: {
    closeIcon: false,
    closeOnMaskClick: false,
    closeOnEsc: false,
  },
  X_ONLY: {
    closeIcon: true,
    closeOnMaskClick: false,
    closeOnEsc: true,
  },
  OPEN: {
    closeIcon: true,
    closeOnMaskClick: true,
    closeOnEsc: true,
  },
  DEFAULT: {
    closeIcon: true,
    closeOnMaskClick: true,
    closeOnEsc: true,
  },
})
const emit = defineEmits(['cancel', 'close', 'update:modelValue', 'submit'])
const props = defineProps({
  backgroundBlur: {
    type: String,
    default: 'div.ovp-container',
  },
  fullScreen: {
    type: Boolean,
    default: false,
  },
  locked: {
    type: Boolean,
    default: false,
  },
  modalTitle: {
    type: String,
    default: '',
  },
  modelValue: {
    type: Boolean,
    required: true,
  },
  name: {
    type: String,
    default: null,
  },
  noMargin: {
    type: Boolean,
    default: false,
  },
  open: {
    type: Boolean,
    default: false,
  },
  target: {
    type: String,
    default: '#modals',
  },
  themes: {
    type: Array,
    default: () => [],
  },
  xOnly: {
    type: Boolean,
    default: false,
  },
})

// DATA
const dialog = ref(undefined)
const id = ref('')
const rootElement = ref(undefined)
const slotProps = {
  close,
  submit,
  cancel,
}

// COMPUTED
/**
 * Retrieves the corresponding {@link DialogCloseTypes} depending on the type props.
 * If the there's no result for the given type prop, it returns the default DialogCloseType
 * @returns {*|{closeOnMaskClick: boolean, closeOnEsc: boolean, closeIcon: boolean}}
 */
const closeOptions = computed(() => {
  if (props.locked) return DialogCloseTypes['LOCKED']
  if (props.xOnly) return DialogCloseTypes['X_ONLY']
  if (props.open) return DialogCloseTypes['OPEN']
  return DialogCloseTypes['DEFAULT']
})

const dialogClasses = computed(() => {
  const baseClasses = {
    'full-screen': props.fullScreen,
    'dialog--no-margin': props.noMargin,
  }

  return [baseClasses, ...props.themes]
})

/**
 * Is used for defining which role-attribute is set on the dialog element
 * If a click outside should close the dialog, then the default 'dialog' is returned
 * Otherwise the 'alertdialog' string is returned, indicating that the dialog is not closeable on an outside click
 * @returns {string}
 */
const role = computed(() => {
  return closeOptions.value.closeOnMaskClick ? 'dialog' : 'alertdialog'
})

const teleportTarget = computed(() => {
  // Teleport the modal on dotcom to the body,
  // as there are scrolling issues, when the modal is opened
  // from within the basketWidget inside the dotcom-navigation
  return isWww.value ? 'body' : props.target
})

// METHODS
/**
 * Add custom listeners to the {@link A11yDialog} object:
 *  * onShow: Set position='fixed' to the body element to prevent background scrolling only if there's not
 *            already another dialog open
 *  * onHide: Add a closing transition for the dialog
 * @param {A11yDialog} dialog
 */
function addInternalEventListener(__dialog) {
  __dialog.on('show', () => {
    // set fixed position on body (scrollY is 0 after setting position)
    const scrollY = window.scrollY
    document.body.style.position = 'fixed'
    document.body.style.top = `-${scrollY}px`
    document.body.style.left = '0'
    document.body.style.right = '0'
    const blurEl = getBlurElement()
    blurEl?.style.setProperty('filter', 'blur(5px)')
    window.OVP_APP_DISABLE_NAVIGATION = true
  })
  __dialog.on('hide', dialogEl => {
    // This is a workaround as the nested transitions leave events are not triggered due to the parent transition
    // See [here]{@link https://forumjs.org/t/nested-transitions-not-engaging-javascript-leave-hooks/7207/3}
    // for detailed information
    const _dialog = dialogEl.getElementsByClassName('dialog')[0]
    const styles = {
      '-webkit-transform': 'scale(1)',
      transform: 'scale(1)',
      transition: 'all .3s ease',
    }
    if (_dialog) {
      Object.assign(_dialog.style, styles)
    }
  })
}

function cancel() {
  emit('cancel')
  close()
}

/**
 * Closes a dialog by emitting the v-model input value (set to false) and a 'close' event.
 * If the dialog has no siblings it removes the position property for the body element to allow background
 * scrolling again.
 */
function close() {
  emit('close')
  emit('update:modelValue', false)
  if (!getHasSiblings()) {
    reset()
  }
}

/**
 * Destroys the current {@link A11yDialog} object if not undefined
 * Takes internally care of hiding the dialog and removing all associated listeners
 */
function destroy() {
  if (dialog.value) {
    close()
    dialog.value.destroy()
  }
}

function getBlurElement() {
  return document.querySelector(props.backgroundBlur)
}

function getHasSiblings() {
  const container = window.document.getElementsByClassName('ovp-configurator')[0]
  const siblingsCount = container?.parentElement.children.length
  return siblingsCount ? siblingsCount > 1 : false
}

function handleEsc(event) {
  if (!closeOptions.value.closeOnEsc) {
    event.stopPropagation()
    event.preventDefault()
  } else {
    close()
  }
}

function handleOverlayClick(event) {
  if (!closeOptions.value.closeOnMaskClick) {
    event.stopPropagation()
    event.preventDefault()
  } else {
    close()
  }
}

/**
 * Instantiates a new {@link A11yDialog} object
 */
async function init() {
  await nextTick()

  events$.emit(EVENT_MODAL.OPEN, props.name)

  const container = window.document.getElementsByClassName('ovp-a11y-container')[0]
  // On .com the basket-widget gets the focus after closing the modal, which leads to weird click issues :/
  if (container && !isWww.value) {
    // CAUTION: if a modal is initially opened the autofocus of an input does break the functionality of the field
    dialog.value = new A11yDialog(container)
    addInternalEventListener(dialog.value)
    dialog.value.show()
    // Manually set focus on rootElement to listen to keydown.esc event for closing the modal
    rootElement.value.focus()
  }
}

/**
 * Reset blocking of navigation, scrolling, remove blur
 */
function reset() {
  if (document.body.style.position === 'fixed') {
    const scrollY = document.body.style.top

    document.body.style.removeProperty('position')
    document.body.style.removeProperty('top')
    document.body.style.removeProperty('left')
    document.body.style.removeProperty('right')

    window.scrollTo(0, Math.abs(parseInt(scrollY || '0', 10)))
  }

  const blurEl = getBlurElement()
  blurEl?.style.removeProperty('filter')
  window.OVP_APP_DISABLE_NAVIGATION = false
}

function submit() {
  emit('submit')
  close()
}

function validateProps() {
  const hasMultipleTypeProps = [props.locked, props.xOnly, props.open].filter(type => type === true).length > 1
  if (hasMultipleTypeProps) {
    logError('Only one type prop can be set')
  }
}

// WATCHERS
watch(
  () => props.modelValue,
  val => {
    if (val) {
      init()
    } else {
      destroy()
    }
  },
  { immediate: true }
)

// If model changes from true to false, then that means that the modal is (in the process of) closing. Needed, because the close-method can be called multiple times for the same modal... (eg. product-wrapper => @closed triggers `modalProps.close()`)
watch(
  () => props.modelValue,
  (newValue, oldValue) => {
    if (oldValue === true && newValue === false) {
      events$.emit(EVENT_MODAL.CLOSE, props.name)
    }
  }
)

// @TODO check whether that really works as intended
watchEffect(() => {
  validateProps()
})

// LIFECYCLE HOOKS
onBeforeMount(() => {
  if (!id.value) id.value = uuidv4()

  events$.on(EVENT_MODAL.CLOSE_ALL, close)
})
// Needed if modal closed by tab sync
onBeforeUnmount(() => {
  if (!getHasSiblings()) {
    reset()
  }
  events$.off(EVENT_MODAL.CLOSE_ALL, close)
})
</script>

<style name="variables">
.ovp-modal-container {
  --modal-offset-outer: 24px;
  --modal-offset-inner: 16px;
}
</style>

<style name="animations" scoped>
.pop-enter-active {
  animation: pop-in 0.3s;
}

.pop-leave-active {
  animation: pop-in 0.3s reverse;
}

@keyframes pop-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>

<style scoped>
.ovp-modal-container,
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ovp-modal-container {
  z-index: 9998;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  background-color: rgba(25, 25, 25, 0.5);
}

.dialog {
  z-index: 9998;
  position: relative;
  background-color: var(--c-primary-neutral-3);
  height: 100%;
  width: 100%;
  overflow: auto;
}

.mobile--dark {
  background-color: var(--c-primary-neutral-2);

  &.full-screen .close-button {
    background-color: inherit;
    top: 12px;
    right: 4px;
  }
}

.desktop--narrow {
  .close-button {
    left: calc((100% - ((100% - 960px) / 2)) - 42px);
    right: 0;
  }
}

@media (--md) {
  .dialog:not(.full-screen) {
    width: 90%;
    height: auto;
    max-height: 80vh;
  }
}

@media (--lg) {
  .dialog:not(.full-screen) {
    width: 960px;
  }
}

.dialog--no-margin .document {
  margin: 0;
}

.document {
  margin: 0 0 46px 0;

  ul {
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }

  @media (--md) {
    margin: 0;
  }
}

.document {
  :deep(.content__wrapper) {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  :deep(.header__title + .content__wrapper) {
    margin-top: 0;
  }
  :deep(.content__wrapper + .footer__actions) {
    margin-top: -8px;
  }
}

.full-screen .close-button {
  background-color: var(--c-primary-neutral-3);
}

.close-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: inherit;
  border: none;
  cursor: pointer;
  z-index: 9999;

  height: 42px;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
  }

  svg {
    height: 24px;
    width: 24px;
  }
}
</style>
