import tracker from 'sanitas-tracking'
import { uniq } from 'lodash'
import { differenceInSeconds } from 'date-fns'

import events$ from '@/services/Events'
import { logError, logWarning } from '@/helpers/Logger'
import { getDistributionPartnerNumber } from '@/helpers/Partner'

import partnerStore from '@/store/partner'
import useCancellation from '@/hooks/useCancellation'
import usePersonDetails from '@/hooks/usePersonDetails'
import useBasket from '@/hooks/useBasket'

import { AGE_RANGES, CATEGORY_TYPE, DISTRIBUTION_PARTNER_TYPE, ROUTE_NAME } from '@/config/constants'
import { FUNNELED_RULES, RULES, ANSWER } from '@/config/tracker'
import {
  EVENT_AIDE,
  EVENT_BASKET,
  EVENT_CONTRACT_OWNER_CHANGED,
  EVENT_CUSTOMER_PORTAL_CHANGED,
  EVENT_FAB,
  EVENT_FORM,
  EVENT_LOGIN,
  EVENT_MODAL,
  EVENT_OVP,
  EVENT_PARTNER_WIDGET,
  EVENT_PERSONS_FORM_DISPLAYED,
  EVENT_PRODUCT,
  EVENT_TRACKING,
  EVENT_TRANSFER_DOCTOR_FROM_OTHER,
} from '@/config/events'

// HOOKS
const { hasEmptyPreInsurer } = useCancellation()
const { hasImmigratedPerson } = useBasket()

// DATA
const PARAMS_TO_REMOVE = ['personId', 'distributionPartnerNumber', 'contractNumber']
const PARAMS_TO_REPLACE = { token: 'reentry' }
const PARAM_PATTERN = ':?__KEY__(\\(.*\\))?\\??'

const PRODUCTION_VALUES = {
  610: ['SSPB01Produkt', 'SSHB01Produkt'],
  310: ['H3SL04Produkt', 'AB304Produkt', 'AB04Produkt', 'AB404Produkt'],
}

const pendingRoutes = []
const COLLECTIVE_PREFIX = 'COLLECTIVE'
let _basket, _productsStructure

const queuedRulesAfterBasketUpdate = []

// METHODS
function anonymizeRoute(route) {
  if (!route) {
    return
  }

  const matched = route.matched[route.matched.length - 1]
  let path = matched?.path

  const params = Object.entries(route.params).reduce((__params, [key, value]) => {
    const re = new RegExp(PARAM_PATTERN.replace('__KEY__', key))

    if (PARAMS_TO_REMOVE.includes(key)) {
      path = path.replace(re, '')
      return __params
    }

    if (Object.keys(PARAMS_TO_REPLACE).includes(key)) {
      path = path.replace(re, PARAMS_TO_REPLACE[key])
      return __params
    }

    if (!value) {
      path = path.replace(re, '')
      return __params
    }

    __params[key] = value
    path = path.replace(re, value)

    return __params
  }, {})

  path = path?.replace(/\/+/g, '/').replace(/(.*)\/$/, '$1')

  return {
    ...route,
    params,
    path,
  }
}

function computeAgeRanges() {
  const { personsDetails } = usePersonDetails()
  const ranges = {
    [AGE_RANGES[0]]: 0,
    [AGE_RANGES[1]]: 0,
    [AGE_RANGES[2]]: 0,
    [AGE_RANGES[3]]: 0,
    [AGE_RANGES[4]]: 0,
  }

  Object.keys(personsDetails.value).forEach(personId => {
    const __age = personsDetails.value[personId].age

    if (__age < 0) ranges[AGE_RANGES[0]]++
    else if (__age <= 18) ranges[AGE_RANGES[1]]++
    else if (__age <= 26) ranges[AGE_RANGES[2]]++
    else if (__age <= 44) ranges[AGE_RANGES[3]]++
    else if (__age <= 63) ranges[AGE_RANGES[4]]++
    else ranges[AGE_RANGES[5]]++
  })

  return ranges
}

function computeProductsValue(persons) {
  return persons.reduce((acc, person) => {
    const personProducts = getPersonProducts(person)
    acc += getProductionValue(personProducts)
    return acc
  }, 0)
}

function computeNumberOfUploads(persons) {
  return persons.reduce((acc, curr) => {
    return acc + curr?.preInsurer?.residencePermitFiles?.length
  }, 0)
}

function computePreInsurerVVG(persons, grouped = false) {
  const insurer = persons.flatMap(person => {
    if (hasEmptyPreInsurer(person.preInsurerVvgs)) {
      return []
    }

    return person.preInsurerVvgs.map(__insurer => {
      return __insurer.preInsurerId
    })
  })

  if (grouped) {
    return uniq(insurer).sort()
  } else {
    return insurer.sort()
  }
}

function computeProductsCombination(persons, productsStructure) {
  if (!productsStructure) {
    return
  }

  const getPersonCategories = person => {
    const personProducts = getPersonProducts(person)
    return personProducts.reduce((categories, product) => {
      categories.push(getProductCategory(productsStructure, product.productId))
      return categories
    }, [])
  }

  const allCategories = persons
    .reduce((__allCategories, person) => {
      for (const category of getPersonCategories(person)) {
        if (!__allCategories.includes(category)) {
          __allCategories.push(category)
        }
      }
      return __allCategories
    }, [])
    .filter(category => category)

  if (!allCategories.length) {
    return ANSWER.NONE
  } else if (allCategories.length === 1) {
    return allCategories.includes(CATEGORY_TYPE.KVG) ? 'KVGOnly' : 'VVGOnly'
  } else {
    return 'KVG&VVG'
  }
}

function computeSecondsTillNow(createDate) {
  const now = new Date()
  const then = new Date(createDate)
  return differenceInSeconds(now, then)
}

function getBooleanAnswer(value) {
  return value ? ANSWER.YES : ANSWER.NO
}

function getPersonProducts(person) {
  return Object.entries(person?.products?.products || [])
    .map(([id, product]) => {
      return {
        productId: id,
        selected: product.selected,
        selectedOption: product.prices.find(price => price.selected),
      }
    })
    .filter(p => p.selected)
}

function getProductCategory(productsStructure, productId) {
  if (!productsStructure) {
    return
  }

  const getParentId = (structure, parentKey, childKey, childId) =>
    Object.values(structure[parentKey]).filter(parent => parent[childKey].includes(childId))[0]?.id

  const groupId = getParentId(productsStructure, 'groups', 'products', productId)
  return getParentId(productsStructure, 'categories', 'groups', groupId)
}

function getProductionValue(products) {
  let productList = products

  if (!Array.isArray(products)) {
    productList = Object.keys(products).map(k => ({ id: k }))
  }

  if (productList.length === 0) {
    return 0
  }

  for (let product of productList) {
    for (let value of Object.keys(PRODUCTION_VALUES)) {
      if (PRODUCTION_VALUES[value].includes(product.productId)) {
        return parseInt(value)
      }
    }
  }
  return 50
}

function getProducts(persons, productsStructure) {
  const products = persons.reduce((acc, person) => {
    const personProducts = getPersonProducts(person)
    const personPrice = getProductionValue(personProducts)

    personProducts.forEach(product => {
      // Set quantity
      if (Object.keys(acc).find(p => p === product.productId)) {
        acc[product.productId].quantity += 1
      } else {
        acc[product.productId] = {
          name: product.productId,
          quantity: 1,
        }
      }

      // Set price to product with highest value
      acc[product.productId].price =
        getProductionValue([{ productId: product.productId }]) === personPrice ? personPrice : 0

      // Temporarily add persons ref
      if (acc[product.productId].persons) {
        acc[product.productId].persons.push(person.id)
      } else {
        acc[product.productId].persons = [person.id]
      }
    })

    return acc
  }, {})

  Object.keys(products).forEach(id => {
    delete products[id].persons
  })

  return Object.values(products)
    .map(p => {
      const category = getProductCategory(productsStructure, p.name) || ''
      return `${category};${p.name};${p.quantity};${p.price}`
    })
    .join(',')
}

function hasMultiYearProducts(persons) {
  return persons.some(person => {
    const personProducts = getPersonProducts(person)
    return personProducts.some(product => {
      const duration = product?.selectedOption?.duration
      return duration && duration !== 1
    })
  })
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function install(app) {
  if (import.meta.env.VITE_TRACKER_LOGGING) {
    window.Sanitas.Tracking.loggable = true
  }

  if (import.meta.env.VITE_TRACKER_ENABLED) {
    window.Sanitas.Tracking.enabled = true
  }

  window.addEventListener('TRACKER_DATALAYER_UPDATED', () => {
    if (!pendingRoutes.length || !tracker.get('basket.id')) {
      return
    }

    if (pendingRoutes.length > 1) {
      logWarning(['Tracker: more than one view pending. Triggering the most recent.', [...pendingRoutes]])
    }

    const route = pendingRoutes.pop()
    triggerPageView(route)
  })

  tracker.set({
    data: { _version: { fe: { ovp: app._component.__name } } },
  })

  events$.on(EVENT_AIDE.EXIT, () => {
    trackAction(RULES.AIDE_EXIT)
  })

  // Modals
  events$.on(EVENT_MODAL.OPEN, modalName => {
    tracker.set({ data: { modalName } })
    trackAction(RULES.OVP_MODAL_OPENED)
  })

  events$.on(EVENT_MODAL.UPDATE, ({ modalName }) => {
    tracker.set({ data: { modalName } })
    trackAction(RULES.OVP_MODAL_UPDATED)
  })

  events$.on(EVENT_MODAL.CLOSE, modalName => {
    tracker.set({ data: { modalName } })
    trackAction(RULES.OVP_MODAL_CLOSE)
  })

  // save-nudge
  events$.on(EVENT_OVP.NUDGE_CLOSE, () => {
    trackAction(RULES.OVP_SAVE_NUDGE_CLOSED)
  })

  // Persons form
  events$.on(EVENT_PERSONS_FORM_DISPLAYED, ({ source, trackingData, forced = false }) => {
    setTrackingSourceComponent(source)
    if (trackingData) {
      tracker.set({ data: { ...trackingData } })
    }

    trackAction(RULES.OVP_SCREEN_PERSONS_FUNNELED, false, { forced })
  })

  // Form Tracking!
  window.setTimeout(() => {
    ;[EVENT_FORM.INIT, EVENT_FORM.INTERACTED, EVENT_FORM.CANCELED, EVENT_FORM.ERROR, EVENT_FORM.SUBMITTED].forEach(
      key => {
        events$.on(key, event => {
          setFormTrackingData(key, event)
          trackAction(key, false, event)
        })
      }
    )
  })

  // Products update related
  events$.on(EVENT_PRODUCT.STRUCTURE_SET, productsStructure => {
    _productsStructure = productsStructure

    if (!_basket?.persons) return

    tracker.set({
      data: {
        basket: {
          products: getProducts(_basket.persons, _productsStructure),
          productsCombination: computeProductsCombination(_basket.persons, _productsStructure),
        },
      },
    })
  })

  events$.on(EVENT_LOGIN.OPENED, source => {
    tracker.set({ data: { personsScreenSource: source } })
  })

  events$.on(EVENT_BASKET.CREATED, () => {
    trackAction(RULES.OVP_BASKET_CREATED)
  })

  // Basket update related
  events$.on(EVENT_BASKET.UPDATED, basket => {
    _basket = { ...basket.basket }

    // if there's no basketId, then it's not a valid basket, yo!
    if (!_basket.basketId) return

    const personsWithOffer = _basket.persons?.filter(p => p.oaOfferId)

    // eslint-disable-next-line sonarjs/no-nested-template-literals
    const personsScreenSource = `${partnerStore.isCollective.value ? `${COLLECTIVE_PREFIX}-` : ''}${_basket.channel}`

    const firstPerson = _basket.persons[0]
    const preInsurer = firstPerson?.preInsurer
    const preInsurerAnswer =
      preInsurer?.preInsurer === false ? ANSWER.NO : firstPerson?.preInsurer?.currentPreInsurerId ?? ANSWER.NONE

    const data = {
      data: {
        basket: {
          id: _basket?.basketId,
          oaBasketId: _basket?.oaBasketId,
          countBaskets: 1,
          countPersonsWithOffer: personsWithOffer.length,
          partnerWidgetBundleCode: _basket?.partnerWidgetBundleCode,
          offerIds: personsWithOffer.map(p => p.oaOfferId).join(';'),
          masterTreaty: _basket?.masterTreaty,
          productionValue: computeProductsValue(_basket.persons),
          products: getProducts(_basket.persons, _productsStructure),
          productsCombination: computeProductsCombination(_basket.persons, _productsStructure),
          secured: getBooleanAnswer(!!_basket.email && !!_basket.mobile),
          immigrant: getBooleanAnswer(hasImmigratedPerson(_basket)),
          countDocuments: computeNumberOfUploads(_basket.persons),
          preInsurerVvgs: computePreInsurerVVG(_basket.persons, true).join(';'),
          hasMultiYearProducts: getBooleanAnswer(hasMultiYearProducts(_basket.persons)),
          hasSignature: getBooleanAnswer(!!_basket.signature?.signatureRequired),
          countPreInsurerVvgs: computePreInsurerVVG(_basket.persons).length,
          existingCustomer: getBooleanAnswer(!!_basket.existingCustomer),
        },
        ageRanges: computeAgeRanges(),
        collectiveContractNumber: _basket.collective?.contractNumber,
        familySize: _basket.persons.length,
        firstPerson: {
          birthYear: firstPerson?.personData.dateOfBirth.split('-')[0],
          gender: firstPerson?.personData.gender,
          currentMovedToCHType: preInsurer?.currentMovedToCHType || ANSWER.NONE,
        },
        location: {
          premiumRegion: _basket.address?.primeRegion,
          plz: _basket.address?.zipCode,
        },
        preInsurer: preInsurerAnswer,
        order: {
          countOffers: personsWithOffer.length,
          confirmationStatus: _basket?.egate?.applicationState,
        },
        personsScreenSource,
      },
    }

    tracker.set(data)

    setDistributionPartners(_basket.distributionPartners)

    // trigger queued rules
    if (queuedRulesAfterBasketUpdate.length > 0) {
      queuedRulesAfterBasketUpdate.forEach(tracking => {
        events$.emit(tracking.ruleId, tracking.payload)
      })
      queuedRulesAfterBasketUpdate.length = 0
    }
  })

  events$.on(EVENT_BASKET.TRACK_AFTER_UPDATE, deferred => {
    queuedRulesAfterBasketUpdate.push(deferred)
  })

  events$.on(EVENT_PRODUCT.DURATION_CHANGED, ({ categoryId, source, productId }) => {
    setTrackingSourceComponent(source)
    const product = `${categoryId};${productId}`
    const productAction = 'modify'
    tracker.set({
      data: { basket: { productAction, product } },
    })
    trackAction(RULES.OVP_DURATION_CHANGED)
  })

  events$.on(EVENT_PRODUCT.FRANCHISE_CHANGED, source => {
    setTrackingSourceComponent(source)
    trackAction(RULES.OVP_FRANCHISE_CHANGED)
  })

  events$.on(EVENT_PRODUCT.DETAIL_OPEN, ({ categoryId, source, productId }) => {
    if (!productId) {
      return
    }

    setTrackingSourceComponent(source)
    const product = `${categoryId};${productId}`
    const productAction = 'view'
    tracker.set({
      data: { basket: { productAction, product } },
    })
    trackAction(RULES.OVP_PRODUCT_VIEWED)
  })

  events$.on(EVENT_PRODUCT.ADDED, ({ categoryId, source, productId }) => {
    setTrackingSourceComponent(source)
    const product = `${categoryId};${productId}`
    const productAction = 'add'
    tracker.set({
      data: { basket: { productAction, product } },
    })
    trackAction(RULES.OVP_PRODUCT_ADDED_TO_BASKET)
  })

  events$.on(EVENT_PRODUCT.REMOVED, ({ categoryId, source, productId }) => {
    setTrackingSourceComponent(source)
    const product = `${categoryId};${productId}`
    const productAction = 'remove'
    tracker.set({
      data: { basket: { productAction, product } },
    })
    trackAction(RULES.OVP_PRODUCT_REMOVED_FROM_BASKET)
  })

  events$.on(EVENT_PRODUCT.REPLACE_DISPLAYED, () => {
    trackAction(RULES.OVP_PRODUCT_REPLACE_DISPLAYED)
  })

  events$.on(EVENT_PRODUCT.SUGGESTION_DISPLAYED, () => {
    trackAction(RULES.OVP_PRODUCT_SUGGESTION_DISPLAYED)
  })

  events$.on(EVENT_OVP.DEFAULT_BASKET_CREATED, source => {
    tracker.set({
      data: {
        defaultOfferSource: source,
      },
    })

    trackAction(EVENT_OVP.DEFAULT_BASKET_CREATED)
  })

  // tracking
  events$.on(EVENT_TRACKING.ENTERED, trackEntered)
  events$.on(EVENT_TRACKING.ROUTE_CHANGE, params => {
    trackEntered(params.opts)
    trackRoute(params.to, params.from)

    // triggers after every path-change, irregardless of any rules/logic
    trackAction(RULES.OVP_ROUTE_CHANGED)
  })

  // transfer doctor from other person
  events$.on(EVENT_TRANSFER_DOCTOR_FROM_OTHER.DISPLAYED, () => {
    trackAction(RULES.OVP_DOCTOR_TRANSFER_FROM_OTHER_DISPLAYED)
  })

  events$.on(EVENT_TRANSFER_DOCTOR_FROM_OTHER.APPLIED, () => {
    trackAction(RULES.OVP_DOCTOR_TRANSFER_FROM_OTHER_APPLIED)
  })

  events$.on(EVENT_BASKET.OPEN_SCREEN_SAVE_MODAL, ({ source }) => {
    setTrackingSourceComponent(source)

    trackAction(RULES.OVP_SAVE_VIEW_DISPLAYED)
  })

  events$.on(EVENT_BASKET.SAVED, ({ basket, source, update }) => {
    setTrackingSourceComponent(source)

    if (basket.mobile) {
      tracker.set({
        data: {
          basket: {
            initToOffer: computeSecondsTillNow(basket.createDate),
          },
        },
      })
      trackAction(update ? RULES.OVP_BASKET_UPDATED : RULES.OVP_BASKET_SAVED)
    } else {
      trackAction(RULES.OVP_BASKET_SAVED_NO_EGATE)
    }
  })

  // Reentry
  events$.on(EVENT_OVP.REENTRY_LOADED, ({ basketId }) => {
    tracker.set({ data: { basket: { id: basketId } } })
  })

  events$.on(EVENT_CONTRACT_OWNER_CHANGED, contractOwnerType => {
    tracker.set({ data: { contractOwnerType } })
    trackAction(RULES.OVP_SPOC_CHANGED)
  })

  events$.on(EVENT_CUSTOMER_PORTAL_CHANGED, value => {
    trackAction(value ? RULES.OVP_KUPO_SELECTED : RULES.OVP_KUPO_UNSELECTED)
  })

  events$.on(EVENT_OVP.APPLICATION_SUBMITTED, ({ basket }) => {
    tracker.set({
      data: {
        basket: {
          initToSubmit: computeSecondsTillNow(basket.createDate),
        },
      },
    })

    trackAction(RULES.OVP_OFFER_SUBMITTED)
  })

  events$.on(EVENT_OVP.SYNC_EXECUTED, () => {
    trackAction(RULES.OVP_SYNC_SESSION_FUNNELED)
  })

  // FAB
  events$.on(EVENT_FAB.OPENED, () => {
    trackAction(RULES.OVP_FAB_OPENED)
  })

  // PartnerWidget
  events$.on(EVENT_PARTNER_WIDGET.DISPLAYED, trackingData => {
    tracker.set({
      data: { ...trackingData },
    })

    trackAction(RULES.OVP_PARTNER_WIDGET_DISPLAYED)
  })
  events$.on(EVENT_PARTNER_WIDGET.PAGE_VIEWED, trackingData => {
    if (trackingData) {
      tracker.set({
        data: { ...trackingData },
      })
    }

    tracker.trigger(tracker.triggerType.VIEW, RULES.OVP_PAGE_VIEWED)
  })
  events$.on(EVENT_PARTNER_WIDGET.GOTO_SANITAS, () => {
    tracker.trigger(tracker.triggerType.VIEW, RULES.OVP_PARTNER_WIDGET_GOTO_SANITAS)
  })

  events$.once(EVENT_BASKET.DIFF_OPENED, () => {
    trackAction(RULES.OVP_BASKET_DIFF_OPENED)
  })
}

export function trackAction(rule, skipHistory, params) {
  if (!rule) throw new Error('Rule parameter missing')

  const forced = !!params?.forced

  // FUNNELED_RULES are only triggered once, unless `force` is set truthy
  if (
    !forced &&
    Object.values(FUNNELED_RULES).includes(rule) &&
    !!tracker.getHistory().find(item => item.event === rule)
  ) {
    return
  }
  tracker.trigger(tracker.triggerType.ACTION, rule, skipHistory, params)
}

function setFormTrackingData(event, payload) {
  const data = tracker.get(`forms.ovp.${payload.form}`) ?? []

  data.push({
    name: payload.form,
    event,
    errors: payload.errors,
    fieldName: payload?.fieldName,
  })

  tracker.set({
    data: {
      forms: {
        ovp: {
          [`${payload.form}`]: data,
        },
      },
    },
  })
}

function setTrackingSourceComponent(sourceComponent) {
  if (!sourceComponent) {
    logError(['sourceComponent falsy', sourceComponent])
    return
  }
  tracker.set({ data: { sourceComponent } })
}

function setDistributionPartners(distributionPartners) {
  if (!distributionPartners || !Array.isArray(distributionPartners)) {
    logWarning(['distributionPartners not set', distributionPartners])
    return
  }

  // special case 'ABSCHLUSS' can have two entries
  const abschluss = distributionPartners
    .filter(partner => partner.distributionPartnerType === DISTRIBUTION_PARTNER_TYPE.ABSCHLUSS)
    .map(partner => partner.distributionPartnerNumber)
    .sort()
    .join(';')

  const trackingDistributionPartners = {
    offerte: getDistributionPartnerNumber(distributionPartners, DISTRIBUTION_PARTNER_TYPE.OFFERTE),
    betreuer: getDistributionPartnerNumber(distributionPartners, DISTRIBUTION_PARTNER_TYPE.BETREUER),
    abschluss,
  }

  tracker.set({
    data: { distributionPartners: trackingDistributionPartners },
  })
}

function trackEntered(opts = { source: undefined, strict: false }) {
  const key = 'sanitas-dvp-ovp_entered-source'
  if (opts.source) {
    // write to sessionStorage
    sessionStorage.setItem(key, opts.source)
  } else {
    const _source = sessionStorage.getItem(key)
    if (_source) {
      tracker.set({
        data: {
          personsScreenSource: _source,
        },
      })
      sessionStorage.removeItem(key)
    }

    // if strict is not truthy, then a source has to be set!
    if ((opts.strict && _source) || !opts.strict) {
      trackAction(RULES.OVP_ENTERED)
    }
  }
}

function trackInteraction(route) {
  let params = route?.params

  // Exception for additional...
  if (route.name === 'additional' || route.name === 'groups-collective') {
    params.categoryId = CATEGORY_TYPE.ADDITIONAL
  }

  if (params?.categoryId) {
    const type = RULES[params.groupId || params.categoryId]
    if (type) {
      trackAction(type)
    } else if (!route?.meta?.aide) {
      logError(`RULE ${params.groupId || params.categoryId} NOT FOUND`)
    }
  }
}

export function trackRoute(to, from) {
  const isLanguageSet = tracker.get('page.language')

  if (!isLanguageSet) {
    tracker.set({
      data: { page: { language: document.documentElement.lang } },
    })
  }

  if (tracker.get('basket.id')) {
    triggerPageView(to, from)
  } else if (to?.name !== ROUTE_NAME.REENTRY) {
    pendingRoutes.push(to)
  }
}

function triggerPageView(to, from) {
  const route = to && anonymizeRoute(to)
  const routeFrom = from && anonymizeRoute(from)

  const level = route.path.split('/').length - 1
  const shouldTriggerPageView =
    route?.path !== routeFrom?.path &&
    (level === 1 ||
      route.name.startsWith('aide-') ||
      route.name.startsWith('application-') ||
      route.name.startsWith('digital-signature') ||
      route.name.includes('basket') ||
      !tracker.getHistory().find(item => item.event === RULES.OVP_PAGE_VIEWED))

  if (shouldTriggerPageView) {
    const language = tracker.get('page.language')
    const host = window.location.protocol + '//www.sanitas.com'
    const baseAliasUrl = host + '/de/privatkunden/praemien.html'
    const baseUrl = host + window.location.pathname.replace(/(.*)\/$/, '$1')
    const url = baseUrl + route.path
    const aliasUrl = baseAliasUrl + route.path
    const pageName = route.name
    const pageTitle = pageName ? pageName[0].toUpperCase() + pageName.slice(1) : null

    const data = {
      page: {
        url,
        aliasUrl,
        route: pageTitle,
        containingPage: pageName,
        title: pageTitle,
        description: `Ovp - ${language.toUpperCase()} - ${pageTitle}`,
        descriptionWithoutLang: `Ovp - ${pageTitle}`,
      },
    }

    tracker.set({ data })
    tracker.trigger(tracker.triggerType.VIEW, RULES.OVP_PAGE_VIEWED)

    triggerRouteBasedActions(to, from)
  }
  trackInteraction(route)
}

/**
 * Triggers funnel rules and other route based actions
 * @param to
 * @param from
 */
function triggerRouteBasedActions(to, from) {
  if (to?.name) {
    switch (to.name) {
      case ROUTE_NAME.BASKET:
      case ROUTE_NAME.AIDE.BASKET:
        trackAction(RULES.OVP_SCREEN_BASKET_FUNNELED)

        if (from?.name === ROUTE_NAME.REENTRY || from?.name === ROUTE_NAME.REENTRY_OA) {
          trackAction(RULES.OVP_DAP_RE_ENTERED)
        }
        break

      case ROUTE_NAME.ONBOARDING:
        trackAction(RULES.OVP_SCREEN_DAP_ONBOARDING_FUNNELED)
        trackAction(RULES.OVP_DAP_ENTERED)
        break

      case ROUTE_NAME.APPLICATION_EXISTING_CUSTOMER_DATA:
        trackAction(RULES.OVP_SCREEN_DAP_EXISTING_CUSTOMER_FUNNELED)
        break

      case ROUTE_NAME.APPLICATION_PERSONAL_DATA:
        trackAction(RULES.OVP_SCREEN_DAP_PERSONS_FUNNELED)
        break

      case ROUTE_NAME.APPLICATION_ADMIN_DATA:
        trackAction(RULES.OVP_SCREEN_DAP_ADMIN_FUNNELED)
        break

      case ROUTE_NAME.APPLICATION_HEALTHQUESTIONS:
        trackAction(RULES.OVP_SCREEN_DAP_HEALTH_QUESTIONS_FUNNELED)
        break

      case ROUTE_NAME.SIGNATURE_ENTRY:
        trackAction(RULES.OVP_SCREEN_SIGNATURE_FUNNELED)
        break

      case ROUTE_NAME.SIGNATURE_VERIFICATION:
        trackAction(RULES.OVP_SCREEN_SIGNATURE_VERIFICATION_FUNNELED)
        break

      case ROUTE_NAME.SUBMIT:
        trackAction(RULES.OVP_SCREEN_DAP_SUMMARY_FUNNELED)
        break
    }
  }
}

export default {
  install,
}
