<template>
  <!-- prettier-ignore -->
  <font-awesome-icon
    class="icon"
    :icon="[prefix, $props.name]"
    v-bind="$attrs"
  />
</template>

<script setup>
import { computed } from 'vue'

import FontAwesomeIcon from '@/config/icons'

// INIT
const props = defineProps({
  art: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
})

// COMPUTED
const prefix = computed(() => {
  switch (props.art) {
    case 'duo':
      return 'fad'

    case 'light':
      return 'fal'

    case 'regular':
      return 'far'

    case 'solid':
      return 'fas'

    case 'thin':
      return 'fat'

    default:
      return console.error('invalid art selected')
  }
})
</script>

<style name="mobile" scoped>
.icon {
  color: var(--c-primary-color-3);
  transition: color 0.3s ease-in-out;
  font-size: inherit;
}
</style>
