import { computed, reactive, readonly } from 'vue'
import { cloneDeep, isEqual } from 'lodash'

import { logInfo } from '@/helpers/Logger'
import { DateTimeUtil } from '@/utils/DateTime'
import { NotFoundError } from '@/utils/CustomErrors'
import { ReactivityUtil } from '@/utils/Reactivity'

import contractAPI from '@/api/contract'
import productStructureStore from '@/store/productStructure'
import useProduct from '@/hooks/useProduct'
import useI18n from '@/hooks/useI18n'

// GLOBAL DATA
const contract = reactive({})
const defaultContract = {
  address: {},
  basketId: null,
  contractStartDate: null,
  language: null,
  masterTreaty: null,
  persons: [],
}

export default function useContract() {
  // HOOKS
  const { t } = useI18n()

  // COMPUTED
  const contractData = computed(() => {
    return {
      address: contract.address,
      contractStartDate: contract.contractStartDate,
    }
  })

  const contractPersons = computed(() => {
    return cloneDeep(contract.persons || [])
  })

  const contractPersonsDetails = computed(() => {
    const { getCategoryIdFromProduct } = useProduct()
    const data = {}
    contractPersons.value.forEach((person, index) => {
      const __number = index + 1
      const __selectedProducts = getSelectedProductsOfContractPerson(person.personId)
      const __recommendations = person.recommendations.map(productId => {
        return Object.assign({}, person.products.products[productId], {
          productId,
          categoryId: getCategoryIdFromProduct(productId),
        })
      })

      data[person.personId] = {
        number: index + 1,
        age: DateTimeUtil.getAge(person.personData.dateOfBirth),
        firstName: person.personData.firstName ?? t('person.person', { number: __number }),
        total: parseFloat(
          __selectedProducts
            .reduce((total, product) => {
              total += product.price.price
              return total
            }, 0)
            .toFixed(2)
        ),
        caveats: getCaveats(person),

        // @note don't use `products` or `recommendations` as a name, as it's already in use by data from backend
        selectedProducts: __selectedProducts,
        productRecommendations: __recommendations,
      }
    })

    return data
  })

  const contractPersonsWithDetails = computed(() => {
    return contractPersons.value.map(person => {
      return Object.assign({}, person, contractPersonsDetails.value[person.personId])
    })
  })

  // METHODS
  function getPersonsForExistingCustomers(basketPersons) {
    const __getContractPerson = person =>
      contractPersonsWithDetails.value.find(p => p.partnerNumber === person.partnerNumber)

    const mapExistingContractDataToPerson = person => {
      const existingPerson = __getContractPerson(person)
      if (!existingPerson) {
        throw new NotFoundError('contract for person', person.partnerNumber)
      }
      /* This is needed as the products are only provided by the api if `modified` and `dynamic` flags of basket are true.
       * Only if products can be modified the information is available.
       * In the other case product information are missing in basket and need to be merged from contract. */
      return Object.assign({}, person, {
        products: existingPerson.products,
        selectedProducts: existingPerson.selectedProducts,
        total: existingPerson.total,
      })
    }

    return (
      basketPersons
        // Only show persons:
        //  - which have an existing contract
        //  - which don't have an existing contract, but have been created/modified with this offer
        .filter(
          // @TODO aren't these all basketPersons anyway? Is there a nonmodified person without contract?
          basketPerson =>
            !!__getContractPerson(basketPerson) || (!__getContractPerson(basketPerson) && basketPerson.contractModified)
        )
        .map(basketPerson =>
          !basketPerson.contractModified ? mapExistingContractDataToPerson(basketPerson) : basketPerson
        )
    )
  }

  function isLegalRepOrNonCustomerContractOwner(contractOwner) {
    return ['LEGALREP', 'NONCUSTOMER'].includes(contractOwner.contractOwnerType)
  }

  function initContract(__contract) {
    __contract.persons[0].personId = __contract.persons[0].partnerNumber
    for (const key in __contract) {
      if (!isEqual(__contract[key], contract[key])) {
        contract[key] = __contract[key]
      }
    }
    logInfo([`%cINIT_CONTRACT`, 'color: green', contract])
  }

  async function installContractOnUpdate(basket) {
    if (!basket.existingCustomer || basket.persons.length === 0) return

    // Check if there are existing customers that don't have a loaded contract yet
    const diff = basket.persons.filter(bp => !contractPersons.value.find(cp => bp.partnerNumber === cp.partnerNumber))

    if (diff.length > 0) {
      await setContract(basket)
    }
  }

  function addPerson(person) {
    person.personId = person.partnerNumber
    contract.persons.push(person)
    logInfo([`%cADD_PERSON`, 'color: green', contract])
  }

  function getCaveats(person) {
    const contractPerson = contractPersons.value.find(p => p?.contractNumber === person?.contractNumber)

    if (!person?.products?.products || !contractPerson?.products?.products) return []
    const personProducts = person.products.products
    const contractProducts = contractPerson.products.products

    return Object.keys(personProducts).filter(p => {
      const cp = contractProducts[p]
      return cp && personProducts[p].selected && personProducts[p].conditionalProduct
    })
  }

  function getContractPerson(personId) {
    return contractPersons.value.find(person => person.personId === personId)
  }

  function getContractProduct(personId, productId) {
    return getContractPerson(personId).products.products[productId]
  }

  function getSelectedProductsOfContractPerson(personId) {
    const __products = productStructureStore.products.value || {}
    const person = getContractPerson(personId)

    // @TODO: this function could be called, before all the contracts are loaded
    // so we need to check for the data before we can continue
    if (!person || !person.products?.products) {
      return []
    }

    return Object.keys(person?.products?.products)
      .map(productId => Object.assign({ productId }, __products[productId], person.products.products[productId]))
      .filter(product => product.selected)
      .map(product => {
        product.price = product.prices.find(p => !!p.selected)
        return product
      })
  }

  async function setContract(payload) {
    logInfo(['%cCONTRACT SET', 'color: GREEN', payload])

    ReactivityUtil.reAssign(contract, defaultContract)
    let isContractSet = false

    const { basketId, contractOwner, language, persons } = payload

    let __contractPersons = persons.filter(p => p.contractNumber)
    /*
    Find the head of the family and move it to the beginning of the array so that the base data are retrieved from this person via INIT_CONTRACT
    If the contractOwner is the LEGAL_REP, use the first person in the basket as the head of family as the basket doesn't contain the LEGAL_REP
   */
    if (contractOwner && contractOwner.contractOwnerType === 'PERSONID') {
      const headOfFamily = __contractPersons.find(p => p.personId === contractOwner.personId)
      if (headOfFamily !== undefined) {
        __contractPersons = __contractPersons.filter(p => p.personId !== headOfFamily.personId)
        __contractPersons.unshift({ ...headOfFamily })
      }
    }

    for (let i = 0; i < persons.length; i++) {
      if (!persons[i].contractNumber) continue

      const contractNumber = persons[i].contractNumber
      const personContract = await contractAPI.getContract(basketId, contractNumber, { language })

      if (!isContractSet) {
        if (personContract) {
          initContract(personContract)
          isContractSet = true
        }
      } else {
        addPerson(personContract.persons[0])
      }
    }
  }

  return {
    // reactive
    contract: readonly(contract),
    // computed
    contractData,
    contractPersonsWithDetails,
    contractPersons,

    // fn
    getCaveats,
    getContractPerson,
    getContractProduct,
    getPersonsForExistingCustomers,
    getSelectedProductsOfContractPerson,
    isLegalRepOrNonCustomerContractOwner,
    installContractOnUpdate,
  }
}
