<template>
  <!-- prettier-ignore -->
  <div
    class="input__outer"
    :class="$props.class"
    :style="$props.style"
  >
    <label
      class="input"
      :class="classes"
    >
      <the-typography
        class="input__title"
        :type="active ? 'caption': 'bodyLargeShort'"
        v-text="autoTitle"
      />

      <div class="input__item">
        <the-typography type="bodyLargeShort">
          <input
            ref="textinputRef"
            v-autofocus="maybeFocus"
            v-maska="$props.mask"
            v-bind="$attrs"
            class="input__element"
            :maxlength="maxLength"
            :readonly="$props.readonly"
            :value="$props.modelValue"
            @[mode]="emitUpdate"
            @blur="emitBlur"
            @focus="focused = true"
          />
        </the-typography>
      </div>
      <the-typography
        class="input__hint"
        type="bodyLargeShort"
        v-text="autoHint"
      />
    </label>
    <slot />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

import debounce from 'lodash/debounce'

import vAutofocus from '@/directives/Focus'

import useBrowser from '@/hooks/useBrowser'
import useI18n from '@/hooks/useI18n'

import { DEFAULT_DELAY_TIME } from '@/config/constants'

// HOOKS
const { browser } = useBrowser()
const { t } = useI18n()

// INIT
defineOptions({
  inheritAttrs: false,
})
const emit = defineEmits(['leave', 'update:modelValue'])
const props = defineProps({
  autofocus: {
    type: Boolean,
    default: false,
  },
  class: {
    type: [String, Object],
    default: null,
  },
  delay: {
    type: Number,
    default: DEFAULT_DELAY_TIME * 3,
  },
  float: {
    type: Boolean,
    default: false,
  },
  floatFixed: {
    type: Number,
    default: 2,
  },
  hint: {
    type: String,
    default: '',
  },
  ignoreUpdateOnBlur: {
    type: Boolean,
    default: false,
  },
  invalid: {
    type: Boolean,
    default: false,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
  isInteger: {
    type: Boolean,
    default: false,
  },
  manualValidation: {
    type: Boolean,
    default: false,
  },
  mask: {
    type: [String, Array, Boolean, Object],
    default: false,
  },
  maxLength: {
    type: Number,
    default: 255,
  },
  modelValue: {
    type: [String, Number],
    default: null,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  style: {
    type: String,
    default: null,
  },
  theme: {
    type: String,
    default: 'light',
  },
  titleLabel: {
    type: String,
    default: '',
  },
  validate: {
    type: Object,
    default: () => {},
  },
})

// DATA
const emitUpdate = debounce($event => update($event), props.delay)
let focused = ref(false)
let textinputRef = ref(null)

// COMPUTED
const active = computed(() => {
  if (focused.value) return true
  if (props.modelValue?.length) return true
  if (props.modelValue !== null && props.modelValue !== undefined) return true
  if (props.isActive) return true
  return false
})

const autoHint = computed(() => {
  if (props.readonly) return
  if (isInvalid.value && props.validate) {
    const errors = Array.from(props.validate?.$errors)
    const error = errors[0]?.$validator

    if (error) {
      return t(`form.error.${error}`)
    }
  }
  return props.hint
})

const autoTitle = computed(() => {
  return `${props.titleLabel} ${isRequired.value ? '*' : ''}`
})

const classes = computed(() => {
  return {
    'input--focused': active.value,
    'input--invalid': isInvalid.value || props.invalid,
    'input--readonly': props.readonly,
    [`input--${props.theme}`]: true,
  }
})

const isInvalid = computed(() => {
  return props.validate?.$invalid && props.validate?.$error
})

const isRequired = computed(() => {
  return props.required || props.validate?.required !== undefined
})

const maybeFocus = computed(() => {
  return props.autofocus && browser.isDesktop
})

const mode = computed(() => {
  return props.lazy ? 'change' : 'input'
})

// METHODS
function emitBlur($event) {
  focused.value = false
  if (props.ignoreUpdateOnBlur) {
    emit('leave', $event.target.value)
    return
  }

  let value = $event.target.value
  const changed = !!(value !== props.modelValue && value.length > 0)
  const emptied = !isRequired.value && (props.modelValue?.length ?? 0) > 0 && (value?.length ?? 0) === 0

  if (changed || emptied) {
    update($event)
  }
}

const update = $event => {
  if (!props.manualValidation) props.validate?.$touch()

  let value = null
  if ($event.target) {
    value = $event.target.value.trim() || null
  }

  if (isRequired.value && value === null) return

  if (props.isInteger) {
    const integer = parseInt(value)
    value = !isNaN(integer) ? integer : null
  }

  if (props.float) {
    const float = parseFloat(value)
    value = !isNaN(float) ? float.toFixed(props.floatFixed) : null
  }
  emit('update:modelValue', value)
}

// EPILOGUE
defineExpose({ nativeElement: textinputRef })
</script>

<style name="mobile" scoped>
.input {
  display: inline-block;
  position: relative;
  width: 100%;
}

.input__outer {
  position: relative;
}

.input__title {
  position: absolute;
  color: var(--c-secondary-neutral-2);
  padding: 4px 10px 0 10px;
  top: 15px;
  width: 100%;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-property: top, font-size, line-height;
  z-index: 1;
  cursor: text;
}

.input__item {
  width: 100%;
  padding-top: 20px;
  border-bottom: 1px solid var(--c-primary-neutral-1);
  cursor: text;

  transition: border-bottom-color 0.2s ease-in-out;
}

.input__element {
  background: none;
  border: none;
  outline: none;
  color: var(--c-primary-neutral-1);
  font-size: inherit;
  line-height: inherit;
  padding: 0 10px 4px;
  width: 100%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  font-family:
    Open Sans,
    sans-serif;

  &::-ms-clear {
    display: none;
  }
}

.input__hint {
  color: var(--c-secondary-neutral-2);
  padding: 0 0 0 10px;

  transition: color 0.2s ease-in-out;
}

.input--focused {
  .input__title {
    top: 0px;
  }

  .input__element {
    opacity: 1;
  }
}

.input--invalid {
  .input__item {
    border-bottom-color: var(--c-secondary-color-1);
  }

  .input__title,
  .input__hint {
    color: var(--c-secondary-color-1);
  }
}

.input--readonly {
  pointer-events: none;

  .input__title {
    cursor: default;
  }

  .input__item {
    border-bottom-color: transparent;
  }

  .input__element {
    cursor: default;
    color: var(--c-secondary-neutral-2);
  }

  .input__hint {
    visibility: hidden;
  }
}

.input--dark {
  .input__item {
    background-color: var(--c-primary-neutral-2);
  }
}

.input--light {
  .input__item {
    background-color: var(--c-primary-neutral-3);
  }
}

:deep(.icon) {
  position: absolute;
  font-size: 20px;
  line-height: 50px;
  background-color: transparent;
  padding: 15px 0;
  right: 15px;
  color: var(--c-primary-color-3);
  outline: none;
  z-index: 1;
  cursor: pointer;
}
</style>
