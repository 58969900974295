<template>
  <!-- prettier-ignore -->
  <div
    class="notifications"
    :class="{'notifications--visible': notifications.length > 0}"
    :style="{ transitionDuration: '300ms' }">
    <div
      v-for="(notification, index) in notifications"
      :key="index"
      class="notifications-item"
      :class="{[`notifications-item--${notification.type}`]: true}"
      @click="event => handleClick(event, notification.id)"
    >
      <span v-html="notification.text" />
      <button class="notifications__close">
        <the-icon
          art="light"
          name="xmark"
        />
      </button>
    </div>
  </div>
</template>

<script setup>
import useNotifications from '@/hooks/useNotifications'

// HOOKS
const { notifications, removeNotification } = useNotifications()

// METHODS
function handleClick(event, notificationId) {
  if (event.target.tagName !== 'A') {
    event.preventDefault()
    removeNotification(notificationId)
  }
}
</script>

<style name="mobile" scoped>
.notifications {
  display: block;
  position: fixed;
  left: 0;
  bottom: 70px;
  z-index: 100000;
  transform: translateY(130px);
  transition: transform 1s;
  width: 100%;

  &--visible {
    transform: translateY(0px);
  }

  &-item {
    position: relative;
    display: flex;
    align-content: center;
    box-sizing: border-box;
    margin: 0 auto 2px;
    width: 100%;
    min-height: 50px;
    padding: 20px;
    cursor: pointer;

    &--info {
      background-color: var(--c-primary-neutral-2);
      color: var(--c-primary-neutral-1);
    }

    &--success {
      background-color: var(--c-primary-color-2);
      color: var(--c-primary-neutral-3);
    }

    &--warning {
      background-color: var(--c-secondary-color-4);
      color: var(--c-primary-neutral-1);

      .icon {
        color: var(--c-primary-neutral-1) !important;
      }
    }

    &--error {
      background-color: var(--c-secondary-color-1);
      color: var(--c-primary-neutral-3);
    }
  }

  &__close {
    position: absolute;
    top: 5px;
    right: 5px;
    border: 0;
    background: transparent;
    font-size: 18px;

    .icon {
      color: var(--c-primary-neutral-3);
    }
  }
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .notifications {
    margin-left: calc(50% - 307.5px);
    width: initial;

    &-item {
      width: 615px;
    }
  }
}
</style>
