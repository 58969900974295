<template>
  <!-- prettier-ignore -->
  <div class="content__wrapper">
    <slot/>
  </div>
</template>

<style name="mobile" scoped>
.content__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: var(--modal-offset-inner);
  padding: var(--modal-offset-inner) var(--modal-offset-outer);
}
</style>
