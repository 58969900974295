import { readonly, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { NOTIFICATION } from '@/config/constants'

import useI18n from '@/hooks/useI18n'

// GLOBAL DATA
const TIMEOUT = 10000 // 10s-ish
const toasts = ref([])

export default function useToast() {
  // HOOKS
  const { t, te } = useI18n()

  // METHODS
  function _add(toast, isPermanent) {
    toasts.value.push(toast)

    if (!isPermanent) {
      window.setTimeout(() => {
        removeToast(toast.id)
      }, TIMEOUT)
    }
  }

  function addToast(toast = {}) {
    if (!toast.id) toast.id = uuidv4()
    if (!toast.type) toast.type = NOTIFICATION.INFO
    if (toast.isClosable === undefined) toast.isClosable = true
    if (toast.isPermanent === undefined) toast.isPermanent = false

    // for the global-error-handler, we pass the error-object
    if (typeof toast.text === 'object') {
      const { errorCode, errorLevel } = toast.text

      toast.type = errorLevel?.toLowerCase() || NOTIFICATION.ERROR
      toast.text = te(`error.${errorCode}`) ? t(`error.${errorCode}`) : t('error.GENERAL')
    }

    toast?.actions?.map(a => {
      a.id = uuidv4()
      return a
    })

    _add(toast, toast.isPermanent)

    return toast.id
  }

  function removeToast(id) {
    toasts.value.splice(
      toasts.value.findIndex(i => i.id === id),
      1
    )
  }

  function removeAllToasts() {
    toasts.value.splice(0, toasts.value.length)
  }

  return {
    addToast,
    removeToast,
    removeAllToasts,
    toasts: readonly(toasts),
  }
}
