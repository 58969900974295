import { cloneDeep, isEmpty } from 'lodash'

import { isRequired, NotFoundError } from '@/utils/CustomErrors'
import { SortUtil } from '@/utils/Sorting'

/** Returns all groups of a category sorted by the order field
 * @param __categories
 * @param __groups
 * @param categoryId
 * @return []
 */
export function getSortedGroupsFromCategory(
  categories = isRequired('categories'),
  groups = isRequired('groups'),
  categoryId = isRequired('categoryId')
) {
  if (!isEmpty(categories) && !categories[categoryId]) throw new NotFoundError('category with categoryId', categoryId)

  if (!categories[categoryId]?.groups) return []

  const groupsCopy = cloneDeep(groups)
  return categories[categoryId].groups.map(groupId => groupsCopy[groupId]).sort(SortUtil.sortByOrder) || []
}

/**
 * Returns all existing products of a category sorted by the order field
 * @param {ProductCategory[]} categories
 * @param {ProductGroup[]} groups
 * @param {ProductProduct[]} products
 * @param {string} categoryId
 * @return []
 */
export function getSortedProductsFromCategory({
  categories = isRequired('categories'),
  groups = isRequired('groups'),
  products = isRequired('products'),
  categoryId = isRequired('categoryId'),
}) {
  if (!categories[categoryId]) throw new NotFoundError('category with categoryId', categoryId)
  return categories[categoryId].groups
    .reduce((acc, groupId) => acc.concat(getSortedProductsFromGroup(groups, products, groupId)), [])
    .sort(SortUtil.sortByOrder)
}

/**
 * Returns all existing products of a group sorted by the order field
 * @param __groups
 * @param __products
 * @param groupId
 * @return []
 */
export function getSortedProductsFromGroup(
  groups = isRequired('groups'),
  products = isRequired('products'),
  groupId = isRequired('groupId')
) {
  let groupsObject = { ...groups }
  if (!groupsObject[groupId]) throw new NotFoundError('group with groupId', groupId)
  const productsCopy = cloneDeep(products)
  return groupsObject[groupId].products
    .map(productId => {
      mapIdToProductId(productsCopy, productId)
      return productsCopy[productId]
    })
    .filter(product => product)
    .sort(SortUtil.sortByOrder)
}

/**
 * The backend used id whereas the frontend works with productId everywhere. This function provides the mapping logic.
 * @param {ProductProduct} products
 * @param {string} productId
 */
export function mapIdToProductId(products, productId) {
  if (!products[productId]) {
    console.warn(`product with id ${productId} not found `)
    return
  }
  products[productId].productId = productId
  delete products[productId].id
}

/**
 *  sortProducts
 */
export function sortProducts(value, products) {
  return value
    .filter(product => !!products[product.productId])
    .map(product => Object.assign(product, products[product.productId]))
    .sort(SortUtil.sortByOrder)
}

/**
 *  updatePriceOptions
 */
export function updatePriceOptions(productPrices, selectedOption) {
  const isVitalProductMatching = item =>
    item.amValue &&
    item.gfValue &&
    item.amValue === selectedOption.amValue &&
    item.gfValue === selectedOption.gfValue &&
    item.duration === selectedOption.duration

  const isFranchiseProductMatching = item =>
    item.value !== undefined && item.value === selectedOption.value && item.duration === selectedOption.duration

  const isProductWithoutFranchiseMatching = item =>
    item.value === undefined &&
    item.amValue === undefined &&
    item.gfValue === undefined &&
    item.duration === selectedOption.duration

  const isSelected = item =>
    isVitalProductMatching(item) || isFranchiseProductMatching(item) || isProductWithoutFranchiseMatching(item)
      ? selectedOption.selected
      : false
  const isToggled = item => (selectedOption.toggle !== undefined ? selectedOption.toggle : item.toggle)

  // Destructuring prevents productPrices to be changed as well
  return productPrices.map(item => {
    return {
      ...item,
      selected: isSelected(item),
      ...(item.toggle !== undefined && { toggle: isToggled(item) }),
    }
  })
}
