import { reactive } from 'vue'
import config from '@/config'
import { VIEW_DESKTOP, VIEW_MOBILE } from '@/config/constants'

// GLOBAL DATA
const browser = reactive({
  isDesktop: null,
  isSafari: null,
  viewportLabel: null,
})

export default function useBrowser() {
  // METHODS

  // TODO Refactor structure
  function _isDesktop(threshold) {
    const mediaQuery = `(min-width: ${threshold}px)`
    return window.matchMedia(mediaQuery)
  }

  function _isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  }

  const { desktopThreshold } = config

  browser.isDesktop = _isDesktop(desktopThreshold).matches
  browser.isSafari = _isSafari()

  const changeState = event => {
    browser.isDesktop = event.matches
    browser.viewportLabel = browser.isDesktop ? VIEW_DESKTOP : VIEW_MOBILE
  }

  _isDesktop(desktopThreshold).addEventListener('change', event => changeState(event))

  return { browser }
}
