<template>
  <!-- prettier-ignore -->
  <label
    class="checkbox"
    :class="classes"
  >
    <the-typography type="bodyLargeLong">
      <input
        :id="id"
        class="checkbox__input"
        type="checkbox"
        :false-value="false"
        :name="name"
        :true-value="true"
        :value="$props.modelValue"
        @change="emitUpdate"
      />

      <the-icon
        class="checkbox__icon"
        :art="icon.art"
        :name="icon.name"
      />

      <div class="checkbox__label">
        <slot>
          <the-typography
            tag="div"
            type="bodyLargeLong"
            v-html="$props.titleLabel"
          />
        </slot>
      </div>
    </the-typography>
  </label>
</template>

<script setup>
import { computed } from 'vue'

// INIT
const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    default: (Math.random() + 1).toString(36).substring(7),
  },
  modelValue: {
    type: Boolean,
  },
  name: {
    type: String,
    required: true,
  },
  titleLabel: {
    type: String,
    default: '',
  },
  validate: {
    type: Object,
    default: () => {},
  },
})

// COMPUTED
const classes = computed(() => {
  return {
    'checkbox--disabled': props.disabled,
    'checkbox--invalid': isInvalid.value,
    'checkbox--selected': props.modelValue === true,
  }
})

const icon = computed(() => {
  const name = props.modelValue === true ? 'square-check' : 'square'

  // Selected
  if (props.modelValue) {
    return {
      art: 'solid',
      name,
    }
  }

  return {
    art: 'light',
    name,
  }
})

const isInvalid = computed(() => {
  return props.validate?.$invalid && props.validate?.$error
})

// METHODS
function emitUpdate() {
  props.validate?.$touch()
  emit('update:modelValue', !props.modelValue)
}
</script>

<style name="mobile" scoped>
.checkbox {
  cursor: pointer;
  display: inline-block;
  position: relative;
  color: var(--c-primary-neutral-1);
  min-height: 20px;

  .checkbox__icon {
    position: absolute;
    font-size: 20px;
    color: inherit;
    top: 2px;
  }

  .checkbox__input {
    pointer-events: none;
    position: absolute;
    opacity: 0;
  }

  .checkbox__label {
    padding-left: 30px;
    user-select: none;
    line-height: 24px;
  }

  &:hover .icon,
  &--selected.checkbox--selected .icon {
    color: var(--c-primary-color-2);
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
</style>
