import { get } from 'lodash'

import { CATEGORY_TYPE, SALES_PARTNER_TYPE } from '@/config/constants'
import { productAVMtypes } from '@/components/MedicalSearch/config/constants'

import { isRequired, MissingParameterError } from '@/utils/CustomErrors'
import { DateTimeUtil } from '@/utils/DateTime'
import { getSortedProductsFromGroup, getSortedProductsFromCategory, sortProducts } from '@/utils/Product'

import partnerStore from '@/store/partner'
import productStructureStore from '@/store/productStructure'
import useFranchise from '@/hooks/useFranchise'
import useI18n from '@/hooks/useI18n'

export default function useProduct() {
  // HOOKS
  const { formatCurrency } = useFranchise()

  // Methods

  /**
   *  composeOptions
   *  TODO: Move to product utils
   *  @param {ProductProduct} product
   *  @param {string} groupId
   *  @param {string} categoryId
   *  @returns {string}
   */
  function composeOptions(product, groupId, categoryId) {
    const { t } = useI18n()

    const optionTitles = getOptionTitles(product.productId, groupId, categoryId)
    const price = product.prices.find(p => p.selected)

    const options = []
    if (hasAccident(product)) {
      options.push(t(`product.accident.${price.toggle ? 'on' : 'off'}`))
    }

    // @note for now, only for the vital-product (m)
    if (hasVitalFranchise(product)) {
      options.push(
        `${optionTitles.amValue.title} ${formatCurrency(price.amValue, null, 0)}`,
        `${optionTitles.gfValue.title} ${formatCurrency(price.gfValue, null, 0)}`
      )
    }

    // @note do not use `hasFranchise` here, as that is used to check, if franchise-selection needs to be display
    if (price.value !== undefined) {
      const franchise = `${optionTitles.franchise.title} ${formatCurrency(price.value, null, 0)} `
      options.push(franchise)
    }

    if (hasDuration(product) && optionTitles.duration) {
      switch (price.duration.toString()) {
        case '1':
          options.push(t('product.contractDuration.single', { year: price.duration }))
          break

        case '3':
        case '5':
          options.push(t('product.contractDuration.multiple', { years: price.duration }))
          break

        case 'F':
          options.push(t('product.contractDuration.extended'))
          break
      }
    }

    return options.join(', ')
  }

  /**
   * getAVMId
   * @todo move to different hooks (e.g. useDoctor)
   * @param {string} productId
   * @returns {}
   */
  function getAVMId(productId) {
    return get(productAVMtypes, productId, '')
  }

  /**
   *  getCategoryFromProduct
   *  @param {string} productId
   *  @returns {ProductCategory}
   */
  function getCategoryFromProduct(productId) {
    return productStructureStore.categories.value[getCategoryIdFromProduct(productId)]
  }

  /**
   *  getCategoryIdFromProduct gets the categoryId from a product.
   *  If the passed product is an upgrade, the productId if it's parent will be used
   *  @param {string} productId
   *  @returns {string | undefined}
   */
  function getCategoryIdFromProduct(productId = isRequired('productId')) {
    if (!productStructureStore?.categories) throw new MissingParameterError('categories')
    if (!productStructureStore?.groups) throw new MissingParameterError('groups')

    const { categories: rawCategories, groups: rawGroups } = productStructureStore

    if (!rawCategories) return undefined

    let __categoryId
    let __productId = productId

    if (isUpgrade(productId)) {
      __productId = getParentProduct(productId)
    }

    Object.keys(rawCategories.value).find(categoryId => {
      const _match = rawCategories.value[categoryId].groups.find(groupId => {
        return rawGroups.value[groupId].products.includes(__productId)
      })

      if (_match) {
        __categoryId = categoryId
      }
    })

    return __categoryId
  }

  /**
   *  getGroupFromProduct
   *  @param {string} productId
   *  @returns {ProductGroup}
   */
  function getGroupFromProduct(productId) {
    return productStructureStore.groups.value[getGroupIdFromProduct(productId)]
  }

  /**
   * getGroupIdFromProduct gets the groupId from a product.
   * If the passed product is an upgrade, the productId if it's parent will be used
   * @param {string} productId
   * @returns {string} groupId
   */
  function getGroupIdFromProduct(productId = isRequired('productId')) {
    let __productId = productId

    if (isUpgrade(productId)) {
      __productId = getParentProduct(productId)
    }

    return Object.keys(productStructureStore.groups.value).find(groupId =>
      productStructureStore.groups.value[groupId].products.includes(__productId)
    )
  }

  /**
   * getOptionTitles
   * @param {string} productId
   * @param {string} groupId
   * @param {string} categoryId
   * @returns {}
   */
  function getOptionTitles(productId, groupId, categoryId) {
    const { t, te } = useI18n()
    const data = { franchise: {}, accident: {} }

    if (te(`content.categories.${categoryId}.accident`)) {
      data.accident = t(`content.categories.${categoryId}.accident`)
    } else if (te(`content.groups.${groupId}.accident`)) {
      data.accident = t(`content.groups.${groupId}.accident`)
    } else if (te(`content.products.${productId}.accident`)) {
      data.accident = t(`content.products.${productId}.accident`)
    }

    if (te(`content.categories.${categoryId}.duration`)) {
      data.duration = t(`content.categories.${categoryId}.duration`)
    } else if (te(`content.groups.${groupId}.duration`)) {
      data.duration = t(`content.groups.${groupId}.duration`)
    } else if (te(`content.products.${productId}.duration`)) {
      data.duration = t(`content.products.${productId}.duration`)
    }

    if (te(`content.categories.${categoryId}.franchise`)) {
      data.franchise = t(`content.categories.${categoryId}.franchise`)
    } else if (te(`content.groups.${groupId}.franchise`)) {
      data.franchise = t(`content.groups.${groupId}.franchise`)
    } else if (te(`content.products.${productId}.franchise`)) {
      data.franchise = t(`content.products.${productId}.franchise`)
    }

    if (te(`content.categories.${categoryId}.amValue`)) {
      data.amValue = t(`content.categories.${categoryId}.amValue`)
    } else if (te(`content.groups.${groupId}.amValue`)) {
      data.amValue = t(`content.groups.${groupId}.amValue`)
    } else if (te(`content.products.${productId}.amValue`)) {
      data.amValue = t(`content.products.${productId}.amValue`)
    }

    if (te(`content.categories.${categoryId}.gfValue`)) {
      data.gfValue = t(`content.categories.${categoryId}.gfValue`)
    } else if (te(`content.groups.${groupId}.gfValue`)) {
      data.gfValue = t(`content.groups.${groupId}.gfValue`)
    } else if (te(`content.products.${productId}.gfValue`)) {
      data.gfValue = t(`content.products.${productId}.gfValue`)
    }

    return data
  }

  /**
   *  hasAccident returns true if the passed product has the accident option
   *  @param {object} product
   *  @returns {boolean}
   */
  function hasAccident(product) {
    // eslint-disable-next-line no-prototype-builtins
    return product.prices.find(p => p.selected)?.hasOwnProperty('toggle')
  }

  /**
   *  hasAccidentToggle returns indicates if the accident toggle should be displayed for a product
   *  @param {{categoryId: string, dateOfBirth: string, kvgContractStartDate: string, product: object}}
   *  @returns {boolean}
   */
  function hasAccidentToggle({ categoryId, dateOfBirth, kvgContractStartDate, product }) {
    if (product && !hasAccident(product)) return false
    if (categoryId !== CATEGORY_TYPE.KVG) return true

    // FLEX-1303 Age at the end of year of contract start date >= 16 -> accident always true for KVG and toggle hidden
    const ageEndOfContractStartYear = DateTimeUtil.getAgeEndOfContractStartYear(dateOfBirth, kvgContractStartDate)
    return ageEndOfContractStartYear >= 16 && ageEndOfContractStartYear <= 65
  }

  /**
   *  hasDuration returns true if a product has a duration option
   *  @param {object} product
   *  @returns {boolean}
   */
  function hasDuration(product) {
    const isDurationVisibleForPartners = [
      SALES_PARTNER_TYPE.PRIVATE_INSURER,
      SALES_PARTNER_TYPE.BROKER,
      SALES_PARTNER_TYPE.MOBILE,
    ].includes(partnerStore.partner.distributionPartnerInfo?.salesPartnerType)

    // eslint-disable-next-line no-prototype-builtins
    const hasProp = product.prices.find(p => p.selected)?.hasOwnProperty('duration')

    return (isDurationVisibleForPartners || product.productId === 'SBVB01Produkt') && hasProp
  }

  /**
   * hasFranchise returns true if a product has a franchise option
   * @NOTE Is used to know, if the franchise-selection needs to be displayed.
   * If you need to check, if there's a value, check for value on the selected options on a product
   * @param {object} product
   * @returns {boolean}
   */
  function hasFranchise(product) {
    const values = []
    product
    product.prices.forEach(p => {
      values.push(p.value)
    })
    return [...new Set(values)].length > 1
  }

  /**
   *  hasOptions returns true if a product has any option at all
   *  @param {object} product
   *  @returns {boolean}
   */
  function hasOptions(product) {
    return hasAccident(product) || hasFranchise(product) || hasDuration(product) || hasVitalFranchise(product)
  }

  /**
   *  hasVitalFranchise returns true if a product has a vital franchise
   *  @param {object} product
   *  @returns {boolean}
   */
  function hasVitalFranchise(product) {
    return product.prices.find(p => !!p.amValue && !!p.gfValue)
  }

  /**
   *  isUpgrade returns the product if it is an upgrade
   *  @param {string} productId
   *  @returns {ProductProduct | undefined}
   */
  function isUpgrade(productId = isRequired('productId')) {
    return Object.keys(productStructureStore.products.value).find(_productId =>
      productStructureStore.products.value[_productId].upgrades.includes(productId)
    )
  }

  // PRIVATE METHODS

  /**
   *  getParentProduct
   *  @param {string} productId
   *  @returns {ProductProduct | undefined}
   */
  function getParentProduct(productId = isRequired('productId')) {
    return Object.keys(productStructureStore.products.value).find(__productId => {
      return productStructureStore.value?.products.value[__productId].upgrades.find(
        __upgradeId => __upgradeId === productId
      )
    })
  }

  /**
   *  getSelectedProductsOfPerson
   *  @todo Replace with selectedProducts on person (everywhere this functin is used, use personWithDetails to get the selectedProducts)
   *  Or even better: add products as param and move it to products util
   *  @param {} personPrducts
   *  @returns {}
   */
  function getSelectedProductsOfPerson(personProducts = isRequired('selected products')) {
    const mergeProduct = productId =>
      Object.assign({ productId }, productStructureStore.products.value[productId], personProducts[productId])
    const replacePrice = product => {
      product.price = product.prices.find(p => !!p.selected)
      return product
    }

    return Object.keys(personProducts)
      .map(productId => mergeProduct(productId))
      .filter(product => product.selected)
      .map(product => replacePrice(product))
  }

  // CORE
  function getReplaceableProducts(productId) {
    if (isUpgrade(productId)) return []

    let replaceableProducts = []
    const categoryId = getCategoryIdFromProduct(productId)
    // products could not have been loaded yet, so we need to check for it
    if (!categoryId) return []
    const category = productStructureStore.categories.value[categoryId]
    const groupId = getGroupIdFromProduct(productId)
    const group = productStructureStore.groups.value[groupId]

    const reducer = (acc, value) => {
      acc.push(value.productId)
      return acc
    }

    const getProductsFromCategory = sortProducts(
      getSortedProductsFromCategory({
        categories: productStructureStore.categories.value,
        groups: productStructureStore.groups.value,
        products: productStructureStore.products.value,
        categoryId,
      }),
      productStructureStore.products.value
    )
    const getProductsFromGroup = sortProducts(
      getSortedProductsFromGroup(productStructureStore.groups.value, productStructureStore.products.value, groupId),
      productStructureStore.products.value
    )

    if (category?.exclusive) {
      replaceableProducts = getProductsFromCategory.reduce(reducer, [])
    } else if (group?.exclusive) {
      replaceableProducts = getProductsFromGroup.reduce(reducer, [])
    }

    return replaceableProducts
  }

  // Expose
  return {
    composeOptions,
    getAVMId,
    getCategoryFromProduct,
    getCategoryIdFromProduct,
    getGroupFromProduct,
    getGroupIdFromProduct,
    getOptionTitles,
    getSelectedProductsOfPerson,
    hasAccident,
    hasAccidentToggle,
    hasDuration,
    hasFranchise,
    hasOptions,
    hasVitalFranchise,
    isUpgrade,

    getReplaceableProducts,
  }
}
