import { logError } from '@/helpers/Logger'

export default function useFranchise() {
  // METHODS
  /**
   * format input as currency/number
   * @param  {String|Number} input
   * @param  {String} currency
   * @param  {Number} fixed
   * @return {String}
   */
  function formatCurrency(input, currency = 'CHF', fixed = 2) {
    let formatted = ''
    const number = parseFloat(input)
    if (Number.isNaN(number)) {
      return formatted
    }
    let options = {
      minimumFractionDigits: fixed,
      maximumFractionDigits: fixed,
    }
    if (currency) {
      options = {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'code',
      }
    }
    try {
      formatted = new Intl.NumberFormat('de-CH', options).format(number)
    } catch (error) {
      logError(`Couldn't format currency: ${error}`)
    }
    return formatted
  }

  // Expose
  return {
    // cd

    // fn
    formatCurrency,
  }
}
