import { createApp } from 'vue'
import Maska from 'maska'
import VueGoogleMaps from '@fawmi/vue-google-maps'

import TheFormModal from '@/components/Modal/TheFormModal'
import TheIcon from '@/components/Container/TheIcon'
import TheMessage from '@/components/Container/TheMessage'
import TheModal from '@/components/Modal/TheModal'
import TheScreen from '@/components/Container/TheScreen'
import TheSection from '@/components/Container/TheSection'
import TheTypography from '@/components/Container/TheTypography'

import config from '@/config'
import events$ from '@/services/Events'
import Cobrowse from '@/plugins/Cobrowse'
import Tracking from '@/plugins/Tracking'
import i18n from '@/plugins/i18n'

import useBasket from '@/hooks/useBasket'
import useToast from '@/hooks/useToast'

import tooltip from '@/directives/Tooltip'

import { EVENT_OVP } from '@/config/events'
import { NOTIFICATION } from '@/config/constants'

// HOOKS
const { updateLanguage } = useBasket()
const { addToast } = useToast()

let isInitialized = false
// eslint-disable-next-line no-undef
const npmVersion = __NPM_VERSION__

function appInit(app) {
  if (!isInitialized) {
    console.info(
      '%c ⚑ sani%ctas DVP',
      'font-weight:bold;color:#00765A;',
      'font-weight:bold;color:#5BAC26;',
      `v${npmVersion}`
    )
    updateLanguage({})

    events$.on(EVENT_OVP.ERROR, event => {
      addToast({
        text: event.error,
        type: NOTIFICATION.ERROR,
      })
    })
    isInitialized = true
  }
  return app
}

function setup(app) {
  app.provide('version$', npmVersion)
  app.provide('appName$', app._component.__name)

  // add global components
  app.directive('tooltip', tooltip)
  app.component('TheFormModal', TheFormModal)
  app.component('TheIcon', TheIcon)
  app.component('TheMessage', TheMessage)
  app.component('TheModal', TheModal)
  app.component('TheScreen', TheScreen)
  app.component('TheSection', TheSection)
  app.component('TheTypography', TheTypography)

  app.use(Maska)
  app.use(i18n)
  app.use(Cobrowse)
  app.use(VueGoogleMaps, { load: { key: config.googleApiKey } })

  if (!isInitialized) {
    app.use(Tracking)
  }

  return appInit(app)
}

export { createApp, setup }
