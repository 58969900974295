<template>
  <!-- prettier-ignore -->
  <form-input
    v-bind="$attrs"
    ref="inputRef"
    class="zipchooser"
    :class="classes"
    :autocomplete="autocomplete"
    :delay="750"
    :hint="autoHint"
    :name="$props.name"
    :invalid="isSearchInvalid"
    :model-value="displayName"
    :placeholder="$props.titleLabel"
    :theme="$props.theme"
    :title-label="$props.titleLabel"
    @focus="reopen"
    @keydown="handleKeyEvent"
    @update:modelValue="search"
  >
    <transition
      mode="out-in"
      name="slide-down"
    >
      <div
        v-if="results.length > 0"
        class="zipchooser-results"
      >
        <button
          v-for="(result, _index) in results"
          :key="result.displayName"
          type="button"
          :class="{active: index === _index}"
          @click="select(result)"
          @mouseenter="hover(_index)"
          v-text="result.displayName"
        />
      </div>
    </transition>

    <div
      v-show="busy"
      class="zipchooser__loader"
    >
      <span v-text="'⚆'"/>
    </div>
  </form-input>
</template>

<script setup>
import { computed, nextTick, reactive, ref, unref } from 'vue'

import masterDataAPI from '@/api/masterData'

import useI18n from '@/hooks/useI18n'

import FormInput from '@/components/Form/TextInput'

// HOOKS
const { t } = useI18n()

// INIT
// Because of binding $attrs the update emit is automatically included
const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  autocomplete: {
    type: String,
    default: 'off',
  },
  contractStartDate: {
    type: String,
    required: true,
  },
  hint: {
    type: String,
    default: '',
  },
  modelValue: {
    type: Object,
    required: true,
  },
  name: {
    type: String,
    default: 'location',
  },
  replace: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: 'light',
  },
  titleLabel: {
    type: String,
    required: true,
  },
  validate: {
    type: Object,
    default: () => {},
  },
})

// DATA
let busy = ref(false)
let error = ref(false)
let hasSearched = ref(false)
let index = ref(null)
let inputRef = ref(null)
let results = reactive([])
let searchValue = ref(null)

// COMPUTED
const autoHint = computed(() => {
  if (isSearchInvalid.value) {
    return t('location.error')
  } else if (results.length === 0 && hasSearched.value) {
    return t('location.noresults')
  } else {
    return props.hint
  }
})

const classes = computed(() => {
  return {
    [`zipchooser--${props.theme}`]: true,
    'zipchooser--invalid': isSearchInvalid.value,
  }
})

const displayName = computed(() => {
  if (searchValue.value !== null) return searchValue.value
  if (props.modelValue?.displayName) return props.modelValue?.displayName
  return null
})

const isSearchInvalid = computed(() => {
  return (
    error.value || (results.length === 0 && hasSearched.value) || (props.validate?.$invalid && props.validate?.$error)
  )
})

// METHODS
function clear() {
  hasSearched.value = false
  error.value = false
  index.value = null
  searchValue.value = null
  results.splice(0)
}

function handleKeyEvent(event) {
  switch (event.keyCode) {
    case 9: // tab
      select(results[index.value])
      break

    case 13: // enter
      event.preventDefault()
      select(results[index.value])
      break

    case 27: // esc
      event.preventDefault()
      clear()
      break

    case 38: // up
      event.preventDefault()
      mark(-1)
      break

    case 40: // down
      event.preventDefault()
      mark(+1)
      break
  }
}

function hover(_index) {
  index.value = _index
}

function mark(sum) {
  index.value = index.value === null ? 0 : index.value + sum
}

function reopen() {
  if (props.modelValue?.zipCode) {
    search(props.modelValue?.zipCode, false)

    if (inputRef?.value?.nativeElement) {
      inputRef.value.nativeElement.select()
    }
  }
}

async function search(value, set = true) {
  if (value === null) return
  if (value === unref(searchValue)) return
  if (props.modelValue?.displayName === value) return

  if (set) {
    props.validate?.$reset()
    searchValue.value = value
  }

  results.splice(0)

  if (value.length >= 3) {
    error.value = false
    busy.value = true

    const payload = {
      query: value,
      contractStartDate: props.contractStartDate,
    }
    const locations = await masterDataAPI.getLocations(payload)
    hasSearched.value = true
    busy.value = false

    if (locations.length === 1) {
      select(locations[0])
    } else if (Array.isArray(locations)) {
      results.push(...locations)
    } else {
      error.value = true
    }
  }
}

async function select(item) {
  let silent = false

  // do not execute touch, when initial value is equal to the update-value
  if (
    item?.displayName === props.modelValue?.displayName &&
    item?.locationNumber === props.modelValue?.locationNumber
  ) {
    silent = true
  }

  if (!item) return

  let updateItem = props.replace ? item : Object.assign({}, props.modelValue, item)

  await nextTick()

  clear()
  Object.assign(props.modelValue, updateItem)
  emit('update:modelValue', updateItem)

  if (props.validate?.$touch && silent === false) {
    props.validate?.$touch()
  }
}
</script>

<style name="animations" scoped>
.slide-down-enter-active,
.slide-down-leave-active,
.slide-up-enter-active,
.slide-up-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-down-enter,
.slide-up-leave-active,
.slide-down-leave-active,
.slide-up-enter {
  opacity: 0;
  height: 0;
  transform: translate(0, -2em);
}
</style>

<style name="mobile" scoped>
.zipchooser {
  position: relative;

  &--invalid {
    color: var(--c-secondary-color-1);

    & :deep(.input__hint) {
      color: var(--c-secondary-color-1);
    }
  }

  &-results {
    position: absolute;
    z-index: 10;

    width: 389px;
    max-height: 190px;

    box-shadow:
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
    overflow-y: auto;

    background-color: var(--c-primary-neutral-3);

    button {
      border: 0;
      outline: 0;
      background: none;
      padding: 10px 15px;
      width: 100%;
      text-align: left;
      cursor: pointer;
      transition: background-color 0.1s ease-in-out;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;

      &:hover,
      &.active {
        background-color: var(--c-secondary-neutral-3);
      }
    }
  }

  &__loader {
    position: absolute;
    right: 0;
    top: 10px;
    color: var(--c-primary-neutral-1);
    z-index: 2;

    height: 35px;
    width: 35px;
    line-height: 35px;
    font-size: 20px;
    text-align: center;
    cursor: wait;
    background-color: var(--c-primary-neutral-3);

    span {
      display: inline-block;
      animation: spin 2s linear infinite;
    }
  }

  &--dark {
    .zipchooser__loader {
      background-color: var(--c-primary-neutral-2);
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
</style>
