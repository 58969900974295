import Keycloak from 'keycloak-js'
import basketStore from '@/store/basket'
import sessionStore from '@/store/session'
import { cloneDeep } from 'lodash'

// GLOBAL DATA
let keycloak
const keycloakOptions = {
  url: import.meta.env.VITE_KEYCLOAK_ENDPOINT,
  realm: import.meta.env.VITE_KEYCLOAK_REALM,
  clientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID,
}

export default function useAuth() {
  // METHODS
  async function initKeycloak(redirectUri, enforceLogin = true) {
    keycloak = new Keycloak(keycloakOptions)
    await keycloak.init({
      onLoad: enforceLogin ? 'login-required' : 'check-sso',
      useNonce: false,
      responseMode: 'query',
      redirectUri,
    })
  }

  async function getToken(platform) {
    switch (platform) {
      case 'ios':
        return new Promise((resolve, reject) => {
          return window.getAppAccessTokenCb(resolve, reject)
        })
      case 'android':
        return window.Auth?.getAppAccessToken()
      default:
        if (!keycloak) {
          await initKeycloak(window.location.href, false)
        }

        try {
          await keycloak.updateToken()
          return keycloak.token
        } catch {
          return undefined
        }
    }
  }

  async function isExistingCustomerLoggedIn() {
    if (!keycloak) {
      await initKeycloak(window.location.href, false)
    }
    return !!keycloak.token
  }

  async function loginExistingCustomer(redirectUri, locale) {
    if (!keycloak) {
      await initKeycloak(redirectUri)
    } else {
      await keycloak.login({ locale, redirectUri })
    }
  }

  /*
  Removes the basket from the session and the localstorage and reloads the page
  In case of an existing customer, the user is logged out as well (if previously logged in)
 */
  async function restartApplication() {
    const __basket = cloneDeep(basketStore.basket)
    await sessionStore.clearSession()

    if (!__basket.existingCustomer) {
      window.location.reload()
      return
    }

    const redirectUri = window.location.href

    if (!keycloak) {
      try {
        await initKeycloak(redirectUri, false)
      } catch {
        // On mobile platforms (ios/android) there's no keycloak so just reload the page
        window.location.reload()
        return
      }
    }

    const isLoggedIn = !!keycloak?.token

    if (isLoggedIn) {
      await keycloak.logout({ redirectUri: redirectUri })
    } else {
      window.location.reload()
    }
  }

  return {
    getToken,
    isExistingCustomerLoggedIn,
    loginExistingCustomer,
    restartApplication,
  }
}
