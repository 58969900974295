import { unref, isRef } from 'vue'
import cloneDeep from 'lodash/cloneDeep'

const isObject = val => val !== null && typeof val === 'object'
const isArray = Array.isArray

function deepUnref(val) {
  const checkedVal = isRef(val) ? unref(val) : val

  if (!isObject(checkedVal)) {
    return checkedVal
  } else if (isArray(checkedVal)) {
    return unrefArray(checkedVal)
  } else {
    return unrefObject(checkedVal)
  }
}

function smartUnref(val) {
  if (val !== null && !isRef(val) && typeof val === 'object') {
    return deepUnref(val)
  }

  return unref(val)
}

function unrefArray(arr) {
  const unreffed = []

  arr.forEach(val => {
    unreffed.push(smartUnref(val))
  })

  return unreffed
}

function unrefObject(obj) {
  const unreffed = {}

  Object.keys(obj).forEach(key => {
    unreffed[key] = smartUnref(obj[key])
  })

  return unreffed
}

export const ReactivityUtil = {
  resetData(reactiveWrapper) {
    if (Array.isArray(reactiveWrapper)) {
      reactiveWrapper.splice(0, reactiveWrapper.length)
    } else {
      for (const key of Object.keys(reactiveWrapper)) {
        delete reactiveWrapper[key]
      }
    }
  },
  // Handle reactive array and object data assignment
  reAssign(reactiveWrapper, newData) {
    // Make sure that the assigned object isn't reactive anymore
    const clonedData = ReactivityUtil.clone(newData)
    if (Array.isArray(reactiveWrapper)) {
      reactiveWrapper.splice(0, reactiveWrapper.length, ...clonedData)
    } else {
      this.resetData(reactiveWrapper)
      Object.assign(reactiveWrapper, clonedData)
    }
  },

  clone(proxy) {
    const unRefed = deepUnref(proxy)
    return cloneDeep(unRefed)
  },
}
