import uniqBy from 'lodash/uniqBy'
import { v4 as uuidv4 } from 'uuid'

import { productAVMtypes } from '@/components/MedicalSearch/config/constants'

/**
 *  addDoctorToBasket adds a doctor to the basket's doctor array
 *  @param {Basket} basket
 *  @param {object} doctor (in FE format)
 *  @returns {Basket} updated basket
 */
export function addDoctorToBasket(basket, doctor) {
  const isExistingDoctor = basket.doctors.find(d => d.zsrNumber === doctor.zsrNumber)

  if (isExistingDoctor) return basket

  const __doctor = createDoctorForBasket(doctor)
  basket.doctors.push(__doctor)
  return basket
}

/**
 * addDoctorToProduct adds a doctor to a product for a person
 * @param {Person} pBasket
 * @param {string} productId
 * @param {object} doctor
 * @returns {Person} updated person
 */
export function addDoctorToProduct(pBasket, productId, doctor) {
  const product = pBasket.products.products[productId]
  product.doctor = doctor
  return pBasket
}

/**
 *  appliesToProduct
 *  @param {string} productId
 *  @param {object} doctor
 *  @returns {boolean}
 */
export function appliesToProduct(productId, doctor) {
  const searchKey = productAVMtypes[productId]
  return !!doctor.medicalOfficeNets.find(n => n.avmDefinition === searchKey)
}

/**
 *  createDoctorForBasket converts doctor object from FE format to BE format
 *  @param {object} doctor
 *  @returns {Doctor} BE-doctor
 */
export function createDoctorForBasket(doctor) {
  const { address } = doctor
  return {
    country: address.country,
    doctorId: uuidv4(),
    name: [doctor.firstName, doctor.lastName].join(' ').trim(),
    street: [address.street, address.houseNumber].join(' ').trim(),
    town: [address.zipCode, address.town].join(' ').trim(),
    zsrNumber: doctor.zsrNumber,
  }
}

/**
 *  getDoctorName gets the display-name for a doctor
 *  @param {Doctor} doctor
 *  @returns {string}
 */
export function getDoctorName(doctor) {
  if (!doctor) return

  const transformedDoctor = createDoctorForBasket(doctor)
  return `${transformedDoctor.name}, ${transformedDoctor.town}`
}

/**
 *  getMedicalProduct gets all selected products with a doctor for a person
 *  @param {Person} person
 *  @returns {object[]}
 */
export function getMedicalProduct(person) {
  return person.selectedProducts.find(product => {
    return product.selected && product.doctor
  })
}

/**
 * Returns doctors that could be transferred from another person.
 * Adults can only copy from other adults, children only from children.
 * @param persons
 * @param currentPersonId
 * @returns {*}
 */
export function getTransferableDoctorFromOtherPerson(persons, currentPersonId) {
  const adultAge = 18
  const currentPerson = persons.find(p => p.personId === currentPersonId)

  const haveSameAgeGroup = (person, otherPerson) => {
    if (person.personId === otherPerson.personId) return false
    if (person.age >= adultAge && otherPerson.age >= adultAge) return true
    if (person.age < adultAge && otherPerson.age < adultAge) return true
    return false
  }

  return (
    persons
      // can only transfer doctor within same age group
      .filter(p => haveSameAgeGroup(p, currentPerson))
      .filter(person => getMedicalProduct(person))
      .map(person => {
        const sourceProduct = getMedicalProduct(person)
        return {
          productId: sourceProduct.productId,
          source: person.personId,
          doctor: sourceProduct.doctor,
        }
      })
  )
}

/**
 *  removeUnusedDoctors removes doctors from basket, which are no longer attached to a selected product
 *  @param {Basket} basket
 *  @returns {Basket} updated basket
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export function removeUnusedDoctors(basket) {
  if (basket.doctors?.length === 0) return basket

  const basketDoctors = basket.doctors.reduce((doctors, doctor) => {
    if (!doctor.zsrNumber) {
      doctors.push(doctor)
    } else {
      basket.persons.some(person => {
        if (!person.products) {
          return false
        }

        const products = Object.values(person.products.products)

        if (products.some(product => product.doctor?.zsrNumber === doctor.zsrNumber && product.selected)) {
          return doctors.push(doctor)
        }

        person.healthQuestionAnswers.some(question => {
          if (question.doctorIds?.length > 0 && question.doctorIds.includes(doctor.doctorId)) {
            return doctors.push(doctor)
          } else if (question?.treatmentCases?.length > 0) {
            return question.treatmentCases.some(treatment => {
              if (treatment.doctorIds?.length > 0 && treatment.doctorIds.includes(doctor.doctorId)) {
                return doctors.push(doctor)
              }
            })
          }
        })
      })
    }
    return doctors
  }, [])

  basket.doctors = uniqBy(basketDoctors, 'doctorId')
  return basket
}
