export const FUNNELED_RULES = {
  OVP_ENTERED: 'OvpEntered',
  OVP_PARTNER_WIDGET_DISPLAYED: 'OvpPartnerWidgetDisplayed',
  OVP_PARTNER_WIDGET_GOTO_SANITAS: 'OvpPartnerWidgetGoToSanitasClicked',
  OVP_SCREEN_PERSONS_FUNNELED: 'OvpScreenPersonsFunneled',
  OVP_SCREEN_BASKET_FUNNELED: 'OvpScreenBasketFunneled',
  OVP_SCREEN_DAP_ONBOARDING_FUNNELED: 'OvpScreenDapOnboardingFunneled',
  OVP_SCREEN_DAP_PERSONS_FUNNELED: 'OvpScreenDapPersonsFunneled',
  OVP_SCREEN_DAP_ADMIN_FUNNELED: 'OvpScreenDapAdminFunneled',
  OVP_SCREEN_DAP_EXISTING_CUSTOMER_FUNNELED: 'OvpScreenDapExistingCustomerFunneled',
  OVP_SCREEN_DAP_HEALTH_QUESTIONS_FUNNELED: 'OvpScreenDapHealthQuestionsFunneled',
  OVP_SCREEN_DAP_SUMMARY_FUNNELED: 'OvpScreenDapSummaryFunneled',
  OVP_SYNC_SESSION_FUNNELED: 'OvpSyncSessionFunneled',
  OVP_SCREEN_SIGNATURE_FUNNELED: 'OvpScreenSignatureFunneled',
  OVP_SCREEN_SIGNATURE_VERIFICATION_FUNNELED: 'OvpScreenSignatureVerificationFunneled',
  // insurance types
  KVG: 'OvpBaseModelFunneled',
  MED: 'OvpBaseModelMedFunneled',
  BASIC: 'OvpBaseModelBasicFunneled',
  TELE: 'OvpBaseModelTeleFunneled',
  MULTI_ACCESS: 'OvpBaseModelMultiFunneled',
  HOSP: 'OvpHospModelFunneled',
  ALLGEMEIN: 'OvpHospModelGeneralFunneled',
  HALBPRIVAT: 'OvpHospModelSemiPrivateFunneled',
  PRIVAT: 'OvpHospModelPrivateFunneled',
  ADDITIONAL: 'OvpAddModelFunneled',
}
export const RULES = {
  ...FUNNELED_RULES,
  AIDE_EXIT: 'AideExit',
  OVP_SAVE_NUDGE_CLOSED: 'OvpSaveNudgeClosed',
  OVP_DAP_ENTERED: 'OvpDapEntered',
  OVP_DAP_RE_ENTERED: 'OvpDapReEntered',
  OVP_DURATION_CHANGED: 'OvpDurationChanged',
  OVP_FAB_OPENED: 'OvpFabOpened',
  OVP_FRANCHISE_CHANGED: 'OvpFranchiseChanged',
  OVP_PRODUCT_VIEWED: 'OvpProductViewed',
  OVP_PRODUCT_ADDED_TO_BASKET: 'OvpProductAddedToBasket',
  OVP_PRODUCT_SUGGESTION_DISPLAYED: 'OvpProductSuggestionDisplayed',
  OVP_PRODUCT_REPLACE_DISPLAYED: 'OvpProductReplaceDisplayed',
  OVP_PRODUCT_REPLACED: 'OvpProductReplaced',
  OVP_PRODUCT_REMOVED_FROM_BASKET: 'OvpProductRemovedFromBasket',
  OVP_DOCTOR_TRANSFER_FROM_OTHER_DISPLAYED: 'OvpDoctorTransferFromOtherDisplayed',
  OVP_DOCTOR_TRANSFER_FROM_OTHER_APPLIED: 'OvpDoctorTransferFromOtherApplied',
  OVP_SAVE_VIEW_DISPLAYED: 'OvpSaveViewDisplayed',
  OVP_BASKET_CREATED: 'OvpBasketCreated',
  OVP_BASKET_UPDATED: 'OvpBasketUpdated',
  OVP_BASKET_DIFF_OPENED: 'OvpBasketDiffOpened',
  OVP_BASKET_SAVED: 'OvpBasketSaved',
  OVP_BASKET_SAVED_NO_EGATE: 'OvpBasketSavedNoEgate',
  OVP_MODAL_OPENED: 'OvpModalOpened',
  OVP_MODAL_UPDATED: 'OvpModalUpdated',
  OVP_MODAL_CLOSE: 'OvpModalClose',
  OVP_PAGE_VIEWED: 'OvpPageViewed',
  OVP_ROUTE_CHANGED: 'OvpRouteChanged',
  OVP_SPOC_CHANGED: 'OvpSpocChanged',
  OVP_KUPO_SELECTED: 'OvpKupoSelected',
  OVP_KUPO_UNSELECTED: 'OvpKupoUnselected',
  OVP_OFFER_SUBMITTED: 'OvpOfferSubmitted',
}
export const ANSWER = {
  NO: 'NO',
  NONE: 'NONE',
  YES: 'YES',
}
