<template>
  <!-- prettier-ignore -->
  <component
    :is="$props.href ? 'a' : 'button'"
    class="button"
    :class="modularizedClasses"
    :disabled="!$props.href && $props.disabled"
    :href="$props.href"
    :target="$props.target"
    :type="!$props.href && $props.type"
    @click="emitClick"
  >
    <the-icon
      v-show="computedIcon.active"
      :class="computedIcon.class"
      :art="computedIcon.art"
      :name="computedIcon.name"
    />
    <span>
      <slot>
        {{ $props.text }}
      </slot>
    </span>
  </component>
</template>

<script setup>
import { computed } from 'vue'

import { loading$ } from '@/services'

// INIT
const emit = defineEmits(['click'])
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  hasLoadingIndicator: {
    type: Boolean,
    default: false,
  },
  href: {
    type: String,
    default: undefined,
  },
  icon: {
    type: String,
    default: undefined,
  },
  iconArt: {
    type: String,
    default: undefined,
  },
  link: {
    type: Boolean,
    default: false,
  },
  negative: {
    type: Boolean,
    default: false,
  },
  plain: {
    type: Boolean,
    default: false,
  },
  primary: {
    type: Boolean,
    default: false,
  },
  secondary: {
    type: Boolean,
    default: false,
  },
  target: {
    type: String,
    default: undefined,
  },
  text: {
    type: String,
    default: '',
  },
  textLink: {
    type: Boolean,
    default: false,
  },
  tiny: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'button',
  },
})

// COMPUTED
const computedIcon = computed(() => {
  return {
    active: !!(isLoading.value || !!props.icon),
    art: !isLoading.value ? props.iconArt || 'regular' : 'regular',
    class: isLoading.value ? 'fa-spin' : '',
    name: !isLoading.value ? props.icon || 'spinner-third' : 'spinner-third',
  }
})

const isLoading = computed(() => {
  return props.hasLoadingIndicator && loading$.getState().active.value
})

const modularizedClasses = computed(() => {
  let classes = ['primary', 'secondary', 'disabled', 'plain', 'link', 'tiny', 'negative', 'textLink'].filter(
    c => props[c]
  )

  if (isLoading.value || props.icon) {
    classes = classes.concat(['button--icon'])
  }

  return classes
})

// METHODS
function emitClick(event) {
  if (props.href && props.disabled) {
    event.preventDefault()
    return
  }
  emit('click', event)
}
</script>

<style name="mobile" scoped>
.button {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  font-size: var(--san-dvp-font-size-base);
  font-weight: bold;

  height: 50px;
  min-height: 50px;
  width: 100%;
  border: 1px solid var(--c-primary-color-3);
  padding: 5px 26px;
  outline: none;
  background: none;
  border-radius: 25px;
  transition: all 0.25s ease-in-out;

  span {
    transition: margin 0.2s ease-in-out;
  }

  span {
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  &:not(:disabled):hover {
    cursor: pointer;
  }

  &:disabled,
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    border-color: transparent;
  }

  &.primary {
    background-color: var(--c-primary-color-3);
    color: var(--c-primary-neutral-3);
    fill: var(--c-primary-neutral-3);

    .icon {
      color: var(--c-primary-neutral-3);
    }

    &:not(:disabled):hover {
      border-color: var(--c-primary-color-2);
      background-color: var(--c-primary-color-2);
      color: var(--c-primary-neutral-3);
      fill: var(--c-primary-neutral-3);

      .icon {
        color: var(--c-primary-neutral-3);
      }

      &.negative {
        border-color: var(--c-primary-neutral-3);
        background-color: var(--c-primary-neutral-3);
        color: var(--c-primary-color-3);
        fill: var(--c-primary-color-3);
      }
    }
  }

  &.secondary {
    color: var(--c-primary-color-3);

    .icon {
      color: var(--c-primary-color-3);
    }

    &.negative {
      color: var(--c-primary-neutral-3);
      fill: var(--c-primary-neutral-3);
      border: 2px solid var(--c-primary-neutral-3);
    }

    &:not(:disabled):hover {
      color: var(--c-primary-color-2);
      border-color: var(--c-primary-color-2);

      .icon {
        color: var(--c-primary-color-2);
      }

      &.negative {
        color: var(--c-secondary-neutral-2);
        fill: var(--c-secondary-neutral-2);
        background-color: var(--c-primary-neutral-3);
        border: 2px solid var(--c-primary-neutral-3);
      }
    }

    &:not(:disabled):focus {
      .loading {
        color: var(--c-primary-neutral-3);
      }
    }
  }

  &.plain {
    min-width: 70px;
    border-color: transparent;
    border-radius: 25px;
    color: var(--c-primary-neutral-1);

    &:not(:disabled):not(.secondary):hover {
      color: var(--c-primary-color-2);
      fill: var(--c-primary-color-2);
    }
  }

  &.link {
    min-width: 70px;
    border-color: transparent;
    border-radius: 25px;
    color: var(--c-primary-color-3);

    &:not(:disabled):not(.secondary):hover {
      color: var(--c-primary-color-2);
      fill: var(--c-primary-color-2);

      .icon {
        color: var(--c-primary-color-2);
      }
    }
  }

  &.textLink {
    min-width: 0;
    min-height: 0;
    height: auto;
    padding: 0;
    border: none;
    justify-content: flex-start;
    color: var(--c-primary-color-3);

    &:not(:disabled):hover {
      color: var(--c-primary-color-2);

      .icon {
        color: var(--c-primary-color-2);
      }
    }
  }

  &.tiny {
    min-height: 30px;
    max-height: 30px;
    font-size: 12px;
  }

  .spin {
    position: absolute;
    left: 10px;
    animation: spin 1.5s linear infinite;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}

.button--icon {
  .icon + span {
    margin: 0 0 0 8px;
  }
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .button {
    min-width: 120px;
    width: auto;
    flex: none;

    &.primary {
      background-color: var(--c-primary-color-3);
      color: var(--c-primary-neutral-3);
      fill: var(--c-primary-neutral-3);

      &:not(:disabled):focus {
        border-color: var(--c-primary-color-2);
        background-color: var(--c-primary-color-2);
        color: var(--c-primary-neutral-3);
        fill: var(--c-primary-neutral-3);

        &.negative {
          border-color: var(--c-primary-neutral-3);
          background-color: var(--c-primary-neutral-3);
          color: var(--c-primary-color-3);
          fill: var(--c-primary-color-3);
        }
      }
    }

    &.secondary {
      color: var(--c-primary-color-3);
      fill: var(--c-primary-color-3);

      &.negative {
        color: var(--c-primary-neutral-3);
        fill: var(--c-primary-neutral-3);
        border: 2px solid var(--c-primary-neutral-3);
      }

      &:not(:disabled):focus {
        color: var(--c-primary-neutral-3);
        fill: var(--c-primary-neutral-3);
        border-color: var(--c-primary-color-3);
        background-color: var(--c-primary-color-3);

        .icon {
          color: var(--c-primary-neutral-3);
        }

        &.negative {
          color: var(--c-secondary-neutral-2);
          fill: var(--c-secondary-neutral-2);
          background-color: var(--c-primary-neutral-3);
          border: 2px solid var(--c-primary-neutral-3);
        }
      }
    }

    &.plain {
      &:not(:disabled):not(.secondary):focus {
        color: var(--c-primary-color-2);
        fill: var(--c-primary-color-2);
      }
    }
  }
}
</style>
