import { readonly, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { NOTIFICATION } from '@/config/constants'

import useI18n from '@/hooks/useI18n'

// GLOBAL DATA
const items = ref([])

export default function useNotifications() {
  // METHODS
  function addNotification(text, type = NOTIFICATION.SUCCESS, lifetime = NOTIFICATION.LIFETIME, id = uuidv4()) {
    _push(
      {
        id,
        text,
        type,
      },
      lifetime
    )

    console.trace('DEPRECATED, USE THE TOASTS!')
  }

  function clearAllNotifications() {
    items.value.splice(0, items.value.length)
  }

  function addErrorNotification(error, lifetime = NOTIFICATION.LIFETIME, id = uuidv4()) {
    const { t } = useI18n()
    const { errorCode, errorLevel } = error

    // don't display same error twice
    if (items.value.some(item => (item.errorCode ? item.errorCode === errorCode : false))) {
      return
    }

    _push(
      {
        id,
        errorCode,
        text: errorCode ? t(`error.${errorCode}`) : error,
        type: errorLevel?.toLowerCase() || NOTIFICATION.ERROR,
      },
      lifetime
    )
  }

  function removeNotification(id) {
    items.value.splice(items.value.findIndex(i => i.id === id))
  }

  function _push(item, lifetime) {
    items.value.push(item)

    if (lifetime > 0) {
      window.setTimeout(() => removeNotification(item.id), lifetime)
    }
  }

  return {
    addNotification,
    clearAllNotifications,
    addErrorNotification,
    notifications: readonly(items),
    removeNotification,
  }
}
