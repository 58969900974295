import { computed } from 'vue'
import { cloneDeep, isNil } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { isRequired } from '@/utils/CustomErrors'
import { updatePersonInBasket } from '@/utils/Person'

import contractStartDateAPI from '@/api/contractStartDate'
import basketStore from '@/store/basket'
import useBasket from '@/hooks/useBasket'
import useI18n from '@/hooks/useI18n'

export default function usePerson() {
  // HOOKS
  const { isExistingCustomerBasket } = useBasket()
  const { t } = useI18n()

  // COMPUTED
  const persons = computed(() => {
    return cloneDeep(basketStore.basket.persons || [])
  })

  const firstPersonId = computed(() => {
    return persons.value.length > 0 ? persons.value[0].personId : undefined
  })

  // METHODS
  async function createPerson(personId = uuidv4()) {
    const defaultContracStartDate = await contractStartDateAPI.getDefaultContractStartDate()

    return {
      order: null,
      contractModified: true,
      collectivePersonGroup: null,
      immigrant: false,
      kvgContractStartDate: defaultContracStartDate.kvgContractStartDate,
      vvgContractStartDate: defaultContracStartDate.vvgContractStartDate,
      personData: {
        dateOfBirth: null,
        firstName: null,
        lastName: null,
        gender: null,
      },
      personId,
    }
  }

  function getPerson(personId) {
    return persons.value.find(p => p.personId === personId)
  }

  /**
   * filterPersonsToValidate returns all the persons which need to be validated to proceed
   * As a basket on for an existing customer (from the service portal channel switch) always
   * contains all the persons of the contract, only the one's that have their contract modified
   * needs to be validated
   * @param {basket} payload
   * @return {Array} persons
   */
  function filterPersonsToValidate(payload) {
    const { existingCustomer, persons: __persons } = payload
    return existingCustomer ? __persons.filter(p => p.contractModified) : __persons
  }

  function getWorkSituationFields({ isVVGOnly, isKVGOnly }, workSituationData) {
    if (isNil(isVVGOnly) || isNil(isKVGOnly)) {
      throw new Error('Missing required parameters')
    }

    const fields = []

    if (askWorkSituation(isVVGOnly)) {
      fields.push({ name: 'workSituation', required: true })
    }

    if (askSecondaryEmployment(isKVGOnly)) {
      fields.push({ name: 'secondaryEmployment', required: true })
      if (workSituationData.secondaryEmployment === true) {
        fields.push({ name: 'professionSecondaryEmployment', required: true })
      }
    }

    return fields
  }

  function askWorkSituation(isVVGOnly) {
    return !isVVGOnly
  }

  function askSecondaryEmployment(isKVGOnly) {
    return !isKVGOnly
  }

  function getFirstName(personId) {
    console.warn('DEPRECATED, use `personsDetails`')

    // const t = app.appContext.config.globalProperties.$t

    const index = getPersonIndex(personId)
    const person = persons.value[index]
    return person.personData.firstName ?? t('person.person', { number: index + 1 })
  }

  /**
   * @param {String} personId
   * @returns {Number} index - index of the person in the basket
   */
  function getPersonIndex(personId) {
    return persons.value.findIndex(p => p.personId === personId)
  }

  function getPersonByPartnerNumber(partnerNumber) {
    return persons.value.find(p => p.partnerNumber === partnerNumber)
  }

  function isUnborn(personId) {
    const person = getPerson(personId)
    return person.age < 0
  }

  function addOrderToPersons(unorderedPersons) {
    return unorderedPersons.map((person, index) => {
      return {
        ...person,
        order: index + 1,
      }
    })
  }

  function isExistingCustomer(personId = isRequired('personId')) {
    if (!isExistingCustomerBasket.value) return false

    const person = getPerson(personId)
    return !!person.partnerNumber
  }

  async function updatePerson(payload) {
    const { formData, person } = payload

    const personData = Object.assign({}, person.personData, formData.personData)

    const response = updatePersonInBasket(cloneDeep(basketStore.basket), person.personId, {
      vvgContractStartDate: formData.vvgContractStartDate,
      personData,
    })

    // FLEX-993 delete cancellation data if contract start date changed after cancellation was answered
    if (person.vvgContractStartDate !== formData.vvgContractStartDate && !isNil(response.cancellationAnswer)) {
      response.cancellationAnswer = null
    }

    return await basketStore.updateOvpBasket(response)
  }

  return {
    // COMPUTED
    firstPersonId,
    persons,

    // METHODS
    addOrderToPersons,
    createPerson,
    filterPersonsToValidate,
    getFirstName,
    getPerson,
    getPersonByPartnerNumber,
    getPersonIndex,

    getWorkSituationFields,
    isExistingCustomer,
    isUnborn,
    updatePerson,
  }
}
