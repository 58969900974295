import { reactive, readonly } from 'vue'
import { isEqual } from 'lodash'

import events$ from '@/services/Events'

import { logInfo } from '@/helpers/Logger'
import { ReactivityUtil } from '@/utils/Reactivity'

import basketAPI from '@/api/basket'
import sessionStore from '@/store/session'

import { EVENT_BASKET } from '@/config/events'

// DATA
const store = reactive({})
const basket = readonly(store)

function basketStore() {
  // METHODS
  async function createOvpBasket(payload) {
    const createdBasket = await basketAPI.createBasket(payload)

    // request failed, abort!
    if (!createdBasket) return

    sessionStore.setSession(createdBasket)
    setBasket(createdBasket)

    return ReactivityUtil.clone(basket)
  }

  function emptyBasket() {
    ReactivityUtil.resetData(store)
  }

  /**
   * Trys to fetch a basket from BE, either for the reentry(token) or "normal" operation
   */
  async function getBasket({ basketId, jwToken, tan, token }, ignoreSession = false) {
    const __basket = token
      ? await basketAPI.getBasketByToken(token, tan)
      : await basketAPI.getBasketById(basketId, jwToken)

    if (ignoreSession) {
      return ReactivityUtil.clone(__basket)
    }

    sessionStore.setSession(__basket)
    setBasket(__basket)

    return ReactivityUtil.clone(basket)
  }

  function isTemporaryBasket(__basket) {
    const __basketData = __basket || basket
    return !__basketData.createDate
  }

  function setBasket(newBasket) {
    const originalBasket = ReactivityUtil.clone(basket)

    let madeChange = false
    for (const key in newBasket) {
      if (!isEqual(newBasket[key], store[key])) {
        store[key] = newBasket[key]
        madeChange = true
      }
    }

    if (madeChange) {
      logInfo(['%cBASKET SET', 'color: green', basket])
      events$.emit(EVENT_BASKET.UPDATED, {
        basket,
        originalBasket,
      })
    }
  }

  /**
   * updateOvpBasket merges the current basket state from the backend with the passed payload, creates/updates the basket in the backend
   * and sets the new basket and session.
   * @param {Object} payload - Can be the whole or a partial basket
   * @param {{simulate: boolean}} options
   *  simulate: whether to make a saving call or only a simulated call to the backend
   * @param params
   * @returns {Promise<Basket>}
   */
  async function updateOvpBasket(payload, options, params) {
    const __options = Object.assign({}, { simulate: false }, options)
    const basketData = Object.assign({}, basket, payload)

    const states = {
      isCreate: isTemporaryBasket(basketData),
      isSimulate: __options.simulate,
    }

    let updatedBasket
    if (states.isCreate) {
      updatedBasket = await basketAPI.createBasket(basketData)
    } else if (states.isSimulate) {
      updatedBasket = await basketAPI.simulateUpdateBasket(params, basketData)
    } else {
      updatedBasket = await basketAPI.updateBasket(basketData.basketId, params, basketData)
    }

    //if (!updatedBasket) throw new Error('failed to create basket')

    if (__options.simulate) {
      return ReactivityUtil.clone(updatedBasket)
    }

    sessionStore.setSession(updatedBasket)
    setBasket(updatedBasket)

    // emit CREATED event, when the basket is "hot"
    if (states.isCreate) {
      events$.emit(EVENT_BASKET.CREATED)
    }

    return ReactivityUtil.clone(basket)
  }

  return {
    basket,
    createOvpBasket,
    emptyBasket,
    getBasket,
    isTemporaryBasket,
    persistBasket: setBasket,
    updateOvpBasket,
  }
}

export default basketStore()
