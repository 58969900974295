import { MissingParameterError } from '@/utils/CustomErrors'

import config from '@/config'
import { getAllContractStartDates } from '@/utils/BasketConfig'

import useBackend from '@/hooks/useBackend'

function product() {
  // HOOKS
  const { doGet, doPost, setup } = useBackend()

  // INIT
  setup('/ovpv3/public', config.microserviceBaseUrl)

  // METHODS

  /**
   *  calculateAllPricesForProduct
   *  @param {string} productId
   *  @param {Basket} payload
   *  @returns {PriceCombination[]}
   */
  async function calculateAllPricesForProduct(productId, payload) {
    const priceCalculations = await doPost(`products/${productId}/calculations`, payload)
    return priceCalculations.data
  }

  /**
   *  getDefaultOfferProductIds sends a POST-request to 'products/defaultOfferProductIds'
   *  to get the default offer productsIds for each person
   *
   */
  async function getDefaultOfferProductIds(basket) {
    const productIds = await doPost('products/defaultOfferProductIds', basket)
    return productIds.data
  }

  /**
   *  getProducts gets the productStructure from the ovpv3-ms api
   *  @param {Basket} __basket
   *  @returns {Promise<ProductStructure>}
   */
  async function getProducts(__basket) {
    if (!__basket?.masterTreaty) throw new MissingParameterError('masterTreaty')

    const startDates = getAllContractStartDates(__basket)
    const payload = {
      collectiveChannel: __basket.collective?.channel,
      collectiveContractNumber: __basket.collective?.contractNumber,
      contractStartDates: Array.from(startDates),
      masterTreaty: __basket.masterTreaty,
    }

    const response = await doGet('products', payload)
    return response.data
  }

  return {
    calculateAllPricesForProduct,
    getDefaultOfferProductIds,
    getProducts,
  }
}

export default product()
