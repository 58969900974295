import { nextTick } from 'vue'

export default {
  mounted: (_el, binding) => {
    // check, if autofocus is activated
    const doAutofocus = binding.value === undefined || binding.value

    // check, if activeElement is NOT YET the one i want to activate
    if (doAutofocus && binding.instance.nativeElement !== document.activeElement) {
      nextTick(() => {
        binding.instance?.nativeElement?.focus()
      })
    }
  },
}
