import { maxLength, minLength, required, helpers } from '@vuelidate/validators'

import { PHONE_NUMBER_TYPE } from '@/config/constants'
import { ValidatorsUtil } from '@/utils/Validators'
import { DateTimeUtil } from '@/utils/DateTime'

import validationsAPI from '@/api/validations'

const { withAsync } = helpers

export const dateOfBirth = {
  required,
  date: ValidatorsUtil.isBirthDate,
}

export const email = {
  required,
  email: ValidatorsUtil.isMail,
}

export const firstName = {
  required,
  minLength: minLength(2),
  maxLength: maxLength(35),
  name: ValidatorsUtil.isName,
}

export const lastName = {
  required,
  minLength: minLength(2),
  maxLength: maxLength(60),
  name: ValidatorsUtil.isName,
}

export const mobile = {
  required,
  mobile: withAsync(async value => {
    return await validationsAPI.validatePhoneNumber(value, PHONE_NUMBER_TYPE.MOBILE)
  }),
}

export const phone = {
  required,
  phone: withAsync(async value => {
    return await validationsAPI.validatePhoneNumber(value)
  }),
}

export const contractStartDate = {
  date: ValidatorsUtil.isDate,
  dateRange: ValidatorsUtil.isInDateRange(
    DateTimeUtil.getMinContractStartDate(),
    DateTimeUtil.getMaxContractStartDate()
  ),
}
