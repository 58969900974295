<template>
  <!-- prettier-ignore -->
  <component
    :is="tag"
    :class="classTypography"
  >
    <slot />
  </component>
</template>

<script setup>
import { computed } from 'vue'

import { TYPOGRAPHY } from '@/config/constants'

// INIT
const props = defineProps({
  tag: {
    type: String,
    default: 'div',
  },
  type: {
    type: String,
    default: TYPOGRAPHY.TYPE_DEFAULT,
    validator: val => {
      return Object.keys(TYPOGRAPHY.TYPES).includes(val)
    },
  },
})

// COMPUTED
const classTypography = computed(() => {
  const type = TYPOGRAPHY.TYPES[mqType.value]
  return [type].flat()
})

const mqType = computed(() => {
  return mapPropToCurrentMq(props.type, TYPOGRAPHY.TYPE_DEFAULT)
})

// METHODS
function mapPropToCurrentMq(prop, fallback) {
  if (typeof prop === 'string') {
    return prop
  }
  // fallback if no match is found
  return fallback
}
</script>

<style scoped>
.heading01 {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.2px;
  font-weight: normal;
}

.heading02 {
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.2px;
  font-weight: bold;
}

.heading03 {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.1px;
  font-weight: 300;
}

.heading04 {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.1px;
  font-weight: bold;
}

.heading05 {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}

.heading06 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  font-weight: bold;
}

.body-medium-long {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  font-weight: normal;
}

.body-large-short {
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
}

.body-large-long {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
}

.display-small {
  font-size: 25px;
  line-height: 32px;
  font-weight: bold;
}

.display-medium {
  font-size: 25px;
  line-height: 32px;
  font-weight: bold;
}

.display-large {
  font-size: 40px;
  line-height: 44px;
  font-weight: bold;
}

.caption {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.25px;
  font-weight: normal;
}
</style>

<style scoped name="desktop">
@media (--md) {
  .heading01 {
    font-size: 50px;
    line-height: 60px;
    letter-spacing: -0.6px;
  }

  .heading02 {
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.2px;
  }

  .heading03 {
    font-size: 25px;
    line-height: 32px;
    letter-spacing: -0.3px;
  }

  .display-small {
    font-size: 32px;
    line-height: 40px;
  }

  .display-medium {
    font-size: 50px;
    line-height: 56px;
    letter-spacing: -0.2px;
  }

  .display-large {
    font-size: 80px;
    line-height: 84px;
    letter-spacing: -0.2px;
  }
}
</style>
