<template>
  <!-- prettier-ignore -->
  <div>
    <label
      class="dropdown"
      :class="classes"
    >
      <the-typography
        class="dropdown__title"
        :type="active ? 'caption': 'bodyLargeShort'"
        v-text="autoTitle"
      />

      <div class="dropdown__item">
        <select
          v-autofocus="maybeFocus"
          class="dropdown__element"
          :disabled="$props.readonly"
          :name="$props.name"
          @blur="focused = false"
          @change="emitUpdate"
          @focus="focused = true"
        >
          <option
            v-if="$props.modelValue === null"
            disabled
            selected="selected"
            :value="null"
            v-text="$props.titleLabel"
          />
          <option
            v-for="(option, index) in $props.options"
            :key="index"
            :disabled="!!option.disabled"
            :selected="option.value === $props.modelValue"
            :value="option.value"
          >
            <template v-if="isSelectedBlurred(option)">
              {{ option.display ? option.display : option.text }}
            </template>
            <template v-else>
              {{ option.text }}
            </template>
          </option>
        </select>
        <the-icon
          v-if="!$props.hideChevron"
          class="dropdown__icon dropdown__icon--fixed"
          art="light"
          name="angle-down"
        />
      </div>

      <the-typography
        class="dropdown__hint"
        type="bodyLargeShort"
        v-text="autoHint"
      />
    </label>
    <slot/>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

import vAutofocus from '@/directives/Focus'

import useBrowser from '@/hooks/useBrowser'
import useI18n from '@/hooks/useI18n'

// HOOKS
const { browser } = useBrowser()
const { t } = useI18n()

// INIT
const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  autofocus: {
    type: Boolean,
    default: true,
  },
  hideChevron: {
    type: Boolean,
    default: false,
  },
  hint: {
    type: String,
    default: '',
  },
  isInteger: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [String, Number],
    default: null,
  },
  name: {
    type: String,
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: 'light',
  },
  titleLabel: {
    type: String,
    default: '',
  },
  validate: {
    type: Object,
    default: () => {},
  },
})

// DATA
const focused = ref(false)

// COMPUTED
const active = computed(() => {
  return focused.value || props.modelValue !== null
})

const autoHint = computed(() => {
  const errors = Object.keys(props.validate?.$params ?? {}).filter(error => !props.validate[error])

  if (invalid.value && errors.length > 0) {
    return t(`form.error.${errors[0]}`)
  } else {
    return props.hint
  }
})

const autoTitle = computed(() => {
  return `${props.titleLabel} ${isRequired.value ? '*' : ''}`
})

const classes = computed(() => {
  return {
    'dropdown--focused': active.value,
    'dropdown--invalid': invalid.value,
    'dropdown--readonly': props.readonly,
    [`dropdown--${props.theme}`]: true,
  }
})

const invalid = computed(() => {
  return props.validate?.$invalid && props.validate?.$error
})

const isRequired = computed(() => {
  return props.required || props.validate?.required !== undefined
})

//TODO remove autofocus if it's not working
const maybeFocus = computed(() => {
  return props.autofocus && browser.isDesktop
})

// METHODS
function emitUpdate(event) {
  let value = event.target.value

  if (props.isInteger) value = parseInt(value)

  props.validate?.$touch()
  emit('update:modelValue', value)
}

function isSelectedBlurred(option) {
  return option.value === props.modelValue && !focused.value
}
</script>

<style name="mobile" scoped>
.dropdown {
  display: inline-block;
  height: 45px;
  position: relative;
  width: 100%;
  pointer-events: none;
}

.dropdown__title {
  position: absolute;
  color: var(--c-secondary-neutral-2);
  padding: 4px 10px 0 10px;
  top: 14px;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-property: top, font-size, line-height;
  z-index: 1;
  width: 100%;
  cursor: pointer;
}

.dropdown__item {
  width: 100%;
  padding-top: 18px;
  border-bottom: 1px solid var(--c-primary-neutral-1);
  cursor: pointer;

  transition: border-bottom-color 0.2s ease-in-out;
}

.dropdown__element {
  background: none;
  border: none;
  outline: none;
  color: var(--c-primary-neutral-1);
  font-size: inherit;
  line-height: inherit;
  height: 26px;
  padding: 0 10px 2px;
  width: 100%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  pointer-events: auto;
  appearance: none;
}

.dropdown__icon {
  position: absolute;
  color: var(--c-primary-neutral-1);
  right: 10px;
  font-size: 16px;
  margin: 0;
}

.dropdown__hint {
  color: var(--c-secondary-neutral-2);
  padding: 0 0 0 10px;

  transition: color 0.2s ease-in-out;
}

.dropdown--focused {
  .dropdown__title {
    top: 0;
  }

  .dropdown__element {
    opacity: 1;
  }
}

.dropdown--invalid {
  .dropdown__item {
    border-bottom-color: var(--c-secondary-color-1);
  }

  .dropdown__title,
  .dropdown__hint {
    color: var(--c-secondary-color-1);
  }
}

.dropdown--dark .dropdown__item {
  background-color: var(--c-primary-neutral-2);
}

.dropdown--light .dropdown__item {
  background-color: var(--c-primary-neutral-3);
}

.dropdown--readonly {
  .dropdown__item {
    border: none;
  }

  .dropdown__element {
    pointer-events: none;
    appearance: none;
    padding-left: 10px;
    color: var(--c-secondary-neutral-2);
  }

  .dropdown__hint {
    visibility: hidden;
  }
}

.dropdown__icon:not(.dropdown__icon--fixed) {
  position: absolute;
  font-size: 20px;
  line-height: 50px;
  background-color: transparent;
  padding: 15px 0;
  right: 15px;
  color: var(--c-primary-color-3);
  outline: none;
  z-index: 1;
  cursor: pointer;
}
</style>
