<template>
  <div
    class="message"
    :class="[`message--${$props.severity}`, `message--theme-${$props.theme}`]"
  >
    <div
      v-if="!$props.noIcon"
      class="message__icon"
    >
      <the-icon
        art="light"
        :name="icon"
      />
    </div>
    <div>
      <slot>
        <the-typography
          type="heading05"
          v-html="$props.titleLabel"
        />

        <the-typography
          type="bodyLargeShort"
          v-html="$props.text"
        />
      </slot>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

// INIT
const props = defineProps({
  noIcon: {
    type: Boolean,
    default: false,
  },
  severity: {
    type: String,
    default: 'info',
  },
  text: {
    type: String,
    default: '',
  },
  theme: {
    type: String,
    default: 'light',
  },
  titleLabel: {
    type: String,
    default: '',
  },
})

// COMPUTED
const icon = computed(() => {
  if (props.severity === 'warning') return 'circle-question'
  else if (props.severity === 'error') return 'exclamation-circle'
  else if (props.severity === 'success') return 'check-circle'
  else return 'circle-info'
})
</script>

<style name="mobile" scoped>
.message {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 8px;

  &__icon {
    align-self: flex-start;

    .icon {
      color: var(--c-primary-neutral-1);
      font-size: 16px;
    }
  }

  &:deep(p) {
    margin: 0;
  }
}

.message--info {
  &.message--theme-light,
  &.message--theme-light .icon {
    background-color: var(--c-primary-neutral-3);
    color: var(--c-primary-neutral-1);
  }

  &.message--theme-dark,
  &.message--theme-dark .icon {
    background-color: var(--c-primary-neutral-2);
    color: var(--c-primary-neutral-1);
  }
}

.message--success,
.message--success .icon {
  background-color: var(--c-primary-color-2);
  color: var(--c-primary-neutral-3);
}

.message--warning,
.message--warning .icon {
  background-color: var(--c-secondary-color-4);
  color: var(--c-primary-neutral-1);
}

.message--error,
.message--error .icon {
  background-color: var(--c-secondary-color-1);
  color: var(--c-primary-neutral-3);
}
</style>
