<template>
  <!-- prettier-ignore -->
  <div class="footer__actions">
    <div class="actions__secondary">
      <slot name="secondary" />
    </div>
    <div class="actions__primary">
      <slot name="primary" />
    </div>
  </div>
</template>

<script setup></script>

<style name="mobile" scoped>
.footer__actions {
  display: flex;
  flex-direction: column-reverse;
  gap: var(--modal-offset-inner);
  padding: var(--modal-offset-outer);
}

.actions__primary,
.actions__secondary {
  display: flex;
  flex-direction: column;
  gap: var(--modal-offset-inner);
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .footer__actions {
    flex-direction: row;
    justify-content: flex-end;
  }

  .actions__primary,
  .actions__secondary {
    flex-direction: row;

    &:deep(.button) {
      min-width: 200px;
    }
  }
}
</style>
